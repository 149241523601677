import {ThreeUnitDetail} from '../ThreeUnitDetail/ThreeUnitDetail';
import {
    Box3,
    Euler,
    ExtrudeGeometry,
    Group,
    Material,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    Shape,
    Vector3
} from 'three';
import {
    ALIGN_BOTTOM,
    ALIGN_CENTER,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    ALIGN_TOP, ALL_FACADES,
    FACADE_CELL_DEFAULT,
    FACADE_DETAIL_TYPE_BACK,
    FACADE_DETAIL_TYPE_GLASS,
    FACADE_DETAIL_TYPE_MAIN,
    FACADE_DETAIL_TYPE_SECOND,
    FACADE_FOR_SECOND, FACADE_MATERIAL_TYPE_CORPUS_MATERIAL,
    FACADE_MODEL_TYPE_PLANE,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    HANDLE_ID_INTEGRATION,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP
} from '../../../../../../common-code/constants';
import {TFacadeModelType} from '../../../../../../common-code/types/TFacadeModelType';
import {TLevel} from '../../../../../../common-code/types/TLevel';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {KITCHEN_VIEW_SKETCH, KITCHEN_VIEW_VISUAL} from '../../../../../constants';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {TFacadeCellName} from '../../../../../../common-code/types/TFacadeCellName';
import {MeshStandardMaterialParameters} from 'three/src/materials/MeshStandardMaterial';
import {ThreeHandle} from '../ThreeHandle/ThreeHandle';
import {TFacadeCalculateType} from '../../../../../../common-code/types/TFacadeCalculateType';
import {TDirectionSideType} from '../../../../../../common-code/types/TDirectionSideType';
import {IImportOffer} from '../../../../../../common-code/interfaces/api/IImportOffer';
import {i18n} from '../../../../../i18n';
import {ISaveFacadeData} from '../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {IFacadeModelData} from '../../../../../../common-code/interfaces/materials/IFacadeModelData';
import {IFacadeMaterialData} from '../../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IFacadeData} from '../../../../../../common-code/interfaces/materials/IFacadeData';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {IDetailKitPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailKitPriceData';
import {CommonHelper, KitchenHelper} from 'common-code';
import {IDetailPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {TSelectItem} from '../../../../../../common-code/types/TSelectItem';
import {TFacadeOpenType} from '../../../../../../common-code/types/TFacadeOpenType';
import {ISaveHandleData} from '../../../../../../common-code/interfaces/saveData/ISaveHandleData';
import {IFacadeHandleData} from '../../../../../../common-code/interfaces/materials/IFacadeHandleData';
import {TFacadeDetailType} from '../../../../../../common-code/types/TFacadeDetailType';
import {IHandlePriceParams} from '../../../../../../common-code/interfaces/catalog/IHandlePriceParams';
import {IHingePriceParams} from '../../../../../../common-code/interfaces/catalog/IHingePriceParams';
import {TFacadeSizes} from '../../../../../../common-code/types/TFacadeSizes';
import {IOffer} from '../../../../../../common-code/interfaces/catalog/IOffer';
import {IHandleData} from '../../../../../../common-code/interfaces/materials/IHandleData';
import { onAfterRenderTransparentForBack, onBeforeRenderTransparentForBack } from '../../../../helpers/ThreeHelper/ThreeHelper';
import {TOptionalFacadeSizes} from '../../../../../../common-code/types/TOptionalFacadeSizes';
import {TFacadeMaterialType} from '../../../../../../common-code/types/materials/TFacadeMaterialType';
import {ThreeMathHelper} from '../../../../helpers/ThreeMathHelper/ThreeMathHelper';

export class ThreeFacade extends ThreeUnitDetail {
    saveData: ISaveFacadeData;
    unit: ThreeUnit;
    threeModelData?: IFacadeModelData;
    threeModel: Group;
    dummy: Group;
    shape: Shape;
    public readonly  HEIGHT_GAP = 100;
    public readonly  WIDTH_GAP = 10;
    facadeMaterialData: IFacadeMaterialData;
    facadeData: IFacadeData;
    materialData: IMaterialData;
    material2Data?: IMaterialData;
    materialTextures: IMaterialTextures;
    material2Textures?: IMaterialTextures;
    glassMaterialData: IMaterialData;
    glassMaterialTextures: IMaterialTextures;
    bodyMaterial?: MeshStandardMaterial;
    bodySecondMaterial?: MeshStandardMaterial;
    glassMaterial?: MeshStandardMaterial;
    handle?: ThreeHandle;
    priceData?: IDetailKitPriceData;
    dummyBox: Box3;

    constructor(options: ISaveFacadeData, unit: ThreeUnit) {
        super(options, unit);
        this.unit = unit;
        this.saveData = this.initThreeUnitSaveData(options);
        this.facadeMaterialData = this.initFacadeMaterialData(this.saveData.facadeMaterial);
        this.facadeData = this.initFacadeData();
        this.materialData = this.initMaterialData();
        this.material2Data = this.initMaterial2Data();
        this.threeModelData = this.getThreeModelData();
        this.threeModel = this.initThreeModel();
        this.shape = new Shape();
        this.dummy = new Group();
        this.dummyBox = new Box3();
        this.dummy.userData.notSwitchView = true;
        this.materialTextures = this.loadTextures(this.materialData);
        if (this.material2Data) {
            this.material2Textures = this.loadTextures(this.material2Data);
        }
        this.glassMaterialData = this.initGlassMaterialData();
        this.glassMaterialTextures = this.loadTextures(this.glassMaterialData);
        this.ready = false;
        this.transparentForBack = this.saveData.transparentForBack !== undefined ?
            this.saveData.transparentForBack : true;
    }

    public initState(isRebuild?: boolean) {
        this.createShape();
        this.createDummy();
        this.createThreeModel();
        this.createHandle();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.addToScene();
        super.createView(isRebuild);
    }

    public getData(): ISaveFacadeData {
        let data: ISaveFacadeData = CommonHelper.deepCopy(super.getData());

        data.facadeMaterial = this.getFacadeMaterialId();
        if (this.handle) {
            data.handle = this.handle.getData();
        }
        data.canStretch = this.unit.canStretch();
        data.isStretch = this.unit.isStretch();

        return data;
    }

    public removeChildren() {
        this.removeHandle();
        super.removeChildren();
    }

    public getCalculateType(): TFacadeCalculateType | undefined {
        let sizeId: string;
        const facadeSizes: TFacadeSizes = this.getSizes();

        sizeId = facadeSizes.width + '_' + facadeSizes.height;
        if (this.saveData.sizesCalculateData &&
            this.saveData.sizesCalculateData[sizeId] &&
            this.saveData.sizesCalculateData[sizeId].calculateType) {
            return this.saveData.sizesCalculateData[sizeId].calculateType;
        }

        return this.saveData.calculateType;
    }

    public isDisableSideTypes(): boolean {
        return this.saveData.disableSideTypes === true;
    }

    public updateViewType() {
        switch (this.viewType) {
            case KITCHEN_VIEW_SKETCH:
                this.dummy.visible = true;
                this.threeModel.visible = false;
                break;
            case KITCHEN_VIEW_VISUAL:
            default:
                this.dummy.visible = false;
                this.threeModel.visible = true;
                break;
        }
    }

    public hideHandle() {
        if (!this.handle) {
            return;
        }
        this.handle.view3d.visible = false;
    }

    public getCellName(): TFacadeCellName {
        return this.saveData.cellName || FACADE_CELL_DEFAULT;
    }

    public getHeight(): number {
        if (!this.saveData.sizes || !this.saveData.sizes.height) {
            throw new Error('error-ThreeFacade-getHeight');
        }

        return +this.saveData.sizes.height;
    }

    public getWidth(): number {
        if (!this.saveData.sizes || !this.saveData.sizes.width) {
            throw new Error('error-ThreeFacade-getLength');
        }

        return +this.saveData.sizes.width;
    }

    public getSizes(): TFacadeSizes {
        if (!this.saveData.sizes) {
            throw new Error('error-ThreeFacade-getSizes');
        }

        return this.saveData.sizes;
    }

    public getDepth(): number {
        if (!this.facadeData || !this.facadeData.depth) {
            throw new Error('error-ThreeFacade-getWidth');
        }

        return +this.facadeData.depth;
    }

    public setPriceData(priceData: IDetailKitPriceData) {
        this.priceData = priceData;
        this.service.setOrderPartsToDetailPrice(priceData, this.service.getOrderParts());
    }

    public getPriceData(): IDetailKitPriceData | undefined {
        return this.priceData;
    }

    public getPrice() {
        let priceData: IDetailKitPriceData | undefined;

        priceData = this.getPriceData();
        if (priceData) {
            return priceData.price;
        }
        return 0;
    }

    public getOldPrice(): number {
        let priceData: IDetailKitPriceData | undefined;

        priceData = this.getPriceData();
        if (priceData && priceData.oldPrice) {
            return priceData.oldPrice;
        }

        return 0;
    }

    public getStock(): number {
        let priceData: IDetailKitPriceData | undefined;

        priceData = this.getPriceData();
        if (priceData && priceData.stock) {
            return priceData.stock;
        }
        return 0;
    }

    public getFormatStock(): string {
        let stock: number;

        stock = this.getStock();
        if (stock) {
            return '' + stock + ' ' + i18n.t('шт');
        }
        return '-';
    }

    public getOfferIds(): string[] {
        let offerIds: string[] = [];
        let offers: IOffer[];
        let offer: IOffer;

        offers = this.getOffers();
        for (offer of offers) {
            offerIds.push(offer.importOffer[this.service.getOfferExternalId()]);
        }

        return offerIds;
    }

    public getOffers(): IOffer[] {
        let offers: IOffer[] = [];
        let itemPriceData: IDetailPriceData;

        if (this.priceData && this.priceData.kit) {
            for (itemPriceData of this.priceData.kit) {
                if (itemPriceData.offer && itemPriceData.count > 0) {
                    offers.push({
                        importOffer: itemPriceData.offer,
                        price: itemPriceData.price,
                        oldPrice: itemPriceData.oldPrice,
                        active: itemPriceData.active,
                        stock: itemPriceData.stock,
                        count: itemPriceData.count,
                        part: itemPriceData.part,
                    });
                }
            }
        }

        return offers;
    }

    public getVendorCode(offer?: IImportOffer): string {
        if (offer && offer.vendorCode) {
            return offer.vendorCode;
        }
        return '-';
    }

    public getSpecName(offer?: IImportOffer): string {
        if (offer && offer.name) {
            return offer.name;
        }

        return this.getName();
    }

    public getSpecCount(): number {
        if (this.priceData && this.priceData.count) {
            return this.priceData.count;
        }

        return 0;
    }

    public getSpecFormatCount(): string {
        let count: number;

        count = this.getSpecCount();
        if (count) {
            return '' + count;
        }

        return '-';
    }

    public getName() {
        return this.facadeMaterialData.title;
    }

    public getModelType(): TFacadeModelType {
        let sizeId: string;
        const facadeSizes: TFacadeSizes = this.getSizes();

        sizeId = facadeSizes.width + '_' + facadeSizes.height;
        if (this.saveData.sizesCalculateData &&
            this.saveData.sizesCalculateData[sizeId] &&
            this.saveData.sizesCalculateData[sizeId].modelType !== undefined) {
            return this.saveData.sizesCalculateData[sizeId].modelType as TFacadeModelType;
        }

        return this.saveData.modelType;
    }

    public getGroupId(): number | undefined {
        return this.saveData.groupId;
    }

    public getCalculateSizes(): TOptionalFacadeSizes | undefined {
        let sizeId: string;
        const facadeSizes: TFacadeSizes = this.getSizes();

        sizeId = facadeSizes.width + '_' + facadeSizes.height;
        if (this.saveData.sizesCalculateData &&
            this.saveData.sizesCalculateData[sizeId] &&
            this.saveData.sizesCalculateData[sizeId].calculateSizes !== undefined) {
            return this.saveData.sizesCalculateData[sizeId].calculateSizes as TOptionalFacadeSizes;
        }

        return this.saveData.calculateSizes;
    }

    public getSideType(): TDirectionSideType {
        let sizeId: string;
        const facadeSizes: TFacadeSizes = this.getSizes();

        sizeId = facadeSizes.width + '_' + facadeSizes.height;
        if (this.saveData.sizesCalculateData &&
            this.saveData.sizesCalculateData[sizeId] &&
            this.saveData.sizesCalculateData[sizeId].sideType !== undefined) {
            return this.saveData.sizesCalculateData[sizeId].sideType as TDirectionSideType;
        }

        return this.saveData.sideType;
    }

    public getAvailableSideTypes(): TSelectItem[] | undefined {
        if (this.isDisableSideTypes()) {
            return undefined;
        }
        if (this.getOpenType() === FACADE_OPEN_TYPE_VERTICAL_PIVOT) {
            return [
                {
                    id: SIDE_TYPE_LEFT,
                    title: i18n.t('Левое')
                },
                {
                    id: SIDE_TYPE_RIGHT,
                    title: i18n.t('Правое')
                }];
        }

        return undefined;
    }

    public getOpenType(): TFacadeOpenType | undefined {
        return this.saveData.openType;
    }

    public getFacadeMaterial(): IFacadeMaterialData {
        return this.facadeMaterialData;
    }

    public getFacadeMaterialId(): string {
        return this.facadeMaterialData.facade === ALL_FACADES ?
            this.facadeMaterialData.material : this.facadeMaterialData.id;
    }

    public getHandlePriceParams(): IHandlePriceParams | undefined {
        if (!this.handle) {
            return undefined;
        }
        return this.handle.getPriceParams();
    }

    public getHingesPriceParams(): IHingePriceParams[] | undefined {
        return this.service.calculateHingesPriceParams(this.unit.getId(), this.saveData.hinges);
    }

    public getOrderPart(): number | undefined {
        return undefined;
    }

    public getFunctionalType(): string | undefined{
        let sizeId: string;
        const facadeSizes: TFacadeSizes = this.getSizes();

        sizeId = facadeSizes.width + '_' + facadeSizes.height;
        if (this.saveData.sizesCalculateData &&
            this.saveData.sizesCalculateData[sizeId] &&
            this.saveData.sizesCalculateData[sizeId].functionalType) {
            return this.saveData.sizesCalculateData[sizeId].functionalType;
        }

        return this.saveData.functionalType;
    }

    public getFacadeMaterialType(): TFacadeMaterialType | undefined{
        return this.saveData.facadeMaterialType;
    }

    public getGroupItems(): ThreeFacade[] {
        let facades: ThreeFacade[] = [];
        let facade: ThreeFacade;

        facades.push(this);

        if (this.getGroupId()) {
            for (facade of this.getOtherFacades()) {
                if (facade.getGroupId() === this.getGroupId()) {
                    facades.push(facade);
                }
            }
        }

        return facades;
    }

    public setLoadModel(type: string, details: Object3D[]) {
        let detail: Object3D;
        let newDetail: Object3D;

        for (detail of details) {
            newDetail = detail.clone();
            newDetail.matrixAutoUpdate = false;
            this.setDetailObjectMaterial(newDetail);
            this.threeModel.add(newDetail);
        }
        this.afterLoadThreeModel();
    }

    public calculateGlobalFrontVector() {
        super.calculateGlobalFrontVector();
        this.setCenterPosition();
    }

    public setCenterPosition() {
        if (!this.view3d.userData.centerPosition ||
            !(this.view3d.userData.centerPosition instanceof Vector3)) {
            this.view3d.userData.centerPosition = new Vector3()
        }
        this.view3d.userData.centerPosition = this.view3d.userData.centerPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public setTransparentForBack() {
        if (this.saveData.notTransparent === true) {
            return;
        }
        this.threeModel.traverse((child) => {
            if (child.userData.notTransparentForBack) {
                return;
            }
            child.userData.parent = this.view3d;
            if (child instanceof Mesh) {
                child.onBeforeRender = onBeforeRenderTransparentForBack;
                child.onAfterRender = onAfterRenderTransparentForBack;
            }
        })
    }

    public getLevel(): TLevel {
        if (!this.saveData.level) {
            this.saveData.level = this.unit.getLevel();
        }

        return this.saveData.level;
    }

    public setFacadeMaterial(data: IFacadeMaterialData): boolean {
        let facadeData: IFacadeData | undefined;


        facadeData = this.service.getFacadeData(data.facade);
        if (!facadeData) {
            return false;
        }
        this.facadeMaterialData = data;
        this.facadeData = facadeData;
        this.bodyMaterial = undefined;
        this.bodySecondMaterial = undefined;
        this.materialData = this.initMaterialData();
        this.material2Data = this.initMaterial2Data();
        this.threeModelData = this.getThreeModelData();
        this.materialTextures = this.loadTextures(this.materialData);
        if (this.material2Data) {
            this.material2Textures = this.loadTextures(this.material2Data);
        }
        this.view3d.remove(this.threeModel);
        this.threeModel = this.initThreeModel();
        this.createThreeModel();
        this.updateAllMatrices();
        return true;
    }

    public isFlipY(): boolean {
        switch (this.getLevel()) {
            case LEVEL_TOP:
                if (this.facadeData.isTopFlipY !== undefined) {
                    return this.facadeData.isTopFlipY;
                }
                break;
            case LEVEL_BOTTOM:
                if (this.facadeData.isBottomFlipY !== undefined) {
                    return this.facadeData.isBottomFlipY;
                }
                break;
        }
        return !!this.saveData.isFlipY;
    }

    public updateHandleByFacadeMaterial(facadeMaterial: IFacadeMaterialData) {
        let projectHandle: IHandleData | undefined;
        let facade: IFacadeData | undefined;
        let facadeHandle: IFacadeHandleData | undefined;

 
        switch (this.getLevel()) {
            case LEVEL_TOP:
                projectHandle = this.service.getTopHandle();
                break;
            case LEVEL_BOTTOM:
                projectHandle = this.service.getBottomHandle();
                break;
        }
        facade = this.service.getFacadeData(facadeMaterial.facade);
        if (facade && facade.handle) {
            facadeHandle = facade.handle;
        }
        if (facadeMaterial.handle) {
            projectHandle = this.service.getHandleData(facadeMaterial.handle);
        }
        if ((facadeHandle && facadeHandle.id !== HANDLE_ID_INTEGRATION) || projectHandle) {
            if (!this.handle) {
                this.createHandle(facadeHandle || projectHandle);
            } else {
                this.handle.setHandleData(projectHandle);
                this.handle.rebuild();
            }
        } else {
            this.handle?.remove();
        }

    }

    public getDummyBox(): Box3 {
        this.dummyBox.makeEmpty().setFromObject(this.dummy);
        if (!ThreeMathHelper.isFiniteBox3(this.dummyBox)) {
            throw new Error('error-ThreeFacade-getDummyBox');
        }
        this.dummyBox.min = this.unit.view3d.worldToLocal(this.dummyBox.min);
        this.dummyBox.max = this.unit.view3d.worldToLocal(this.dummyBox.max);

        return this.dummyBox;
    }

    public getIsDimensions(): boolean {
        return this.saveData.isDimensions ?? false;
    }

    public calculateHandleData(
        saveHandleData?: ISaveHandleData,
        facadeHandleData?: IFacadeHandleData | IHandleData,
    ) : ISaveHandleData | undefined {
        return this.service.calculateHandleData(
            saveHandleData,
            facadeHandleData,
            this.getOpenType(),
            this.getLevel(),
            this.getSizes(),
            this.getSideType()
        );
    }

    public setDummyTransparent(value: boolean) {
    }

    public isIntegrationHandle(): boolean {
        return false;
    }

    protected afterLoadThreeModel() {
        this.dummy.visible = this.viewType === KITCHEN_VIEW_SKETCH;
        this.scaleThreeModel();
        this.unit.updateFacadeModel(this);
    }

    protected setIsDimensions() {
        this.saveData.isDimensions = this.saveData.isDimensions ?? false;
    }

    protected createHandle(initHandle?: IFacadeHandleData | IHandleData) {
        let handleSaveData: ISaveHandleData | undefined;
        let handleData: IHandleData | undefined;
        let facadeHandleData: IFacadeHandleData | IHandleData | undefined;

        switch (this.getLevel()) {
            case LEVEL_TOP:
                handleData = this.service.getTopHandle()
                break;
            case LEVEL_BOTTOM:
                handleData = this.service.getBottomHandle()
                break;
        }
        facadeHandleData = initHandle ? initHandle :
            (handleData && !handleData.notPrice ? handleData : this.facadeData.handle);
        handleSaveData = this.calculateHandleData(this.saveData.handle, facadeHandleData);
        if (!handleSaveData) {
            return;
        }
        this.handle = new ThreeHandle(CommonHelper.deepCopy(handleSaveData), this);
        this.handle.initState();
        this.handle.createView();
        this.view3d.add(this.handle.view3d);
    }

    protected getOtherFacades(): ThreeFacade[] {
        return this.unit.facades || [];
    }

    protected removeHandle() {
        if (!this.handle) {
            return;
        }
        this.view3d.remove(this.handle.view3d);
        this.handle.remove();
        this.handle = undefined;
    }

    protected setDetailObjectMaterial(detail: Object3D, name?: string) {
        let child: Object3D;

        if (detail instanceof Mesh) {
            this.setDetailMaterial(detail);
        }
        if (detail instanceof Group) {
            for (child of detail.children) {
                this.setDetailObjectMaterial(child, name || detail.name);
            }
        }
    }

    protected setDetailMaterial(detail: Mesh, name?: string) {
        let material: Material;
        let detailName: TFacadeDetailType;

        if (name) {
            detailName = name as TFacadeDetailType;
        } else {
            detailName = detail.name as TFacadeDetailType;
        }
        if (!detailName.includes('custom')) {
            if (Array.isArray(detail.material)) {
                for (material of detail.material) {
                    material.dispose();
                }
            } else {
                if (detail.material instanceof MeshStandardMaterial) {
                    detail.material.dispose();
                }
            }
            detail.material = this.getMaterialByName(detailName);
        }
    }

    protected getMaterialByName(name: TFacadeDetailType): MeshStandardMaterial {
        let material: MeshStandardMaterial;
        switch (name) {
            case FACADE_DETAIL_TYPE_GLASS:
                material = this.getGlassMaterial();
                break;
            case FACADE_DETAIL_TYPE_BACK:
                material = this.getBackMaterial();
                break;
            case FACADE_DETAIL_TYPE_SECOND:
                material = this.getSecondMaterial();
                break;
            case FACADE_DETAIL_TYPE_MAIN:
            default:
                if (this.saveData.for === FACADE_FOR_SECOND) {
                    material = this.getSecondMaterial();
                } else {
                    material = this.getBodyMaterial();
                }
                break;

        }

        return material;
    }

    protected getGlassMaterial(): MeshStandardMaterial {
        if (!this.glassMaterial) {
            this.glassMaterial = new MeshStandardMaterial({
                color: this.glassMaterialData.color || '#ffffff',
                transparent: true,
                opacity: this.glassMaterialData.opacity || 0.9,
                metalness: 0.5,
                roughness: 0.1,
                map: this.glassMaterialTextures.texture || null,
                normalMap: this.glassMaterialTextures.normal || null,
                roughnessMap: this.glassMaterialTextures.roughness || null,
                envMapIntensity: 5
            });
        }

        return this.glassMaterial;
    }

    protected getBackMaterial(): MeshStandardMaterial {
        return this.getBodyMaterial();
    }

    protected getBodyMaterial(): MeshStandardMaterial {
        if (!this.bodyMaterial) {
            let parameters: MeshStandardMaterialParameters;

            parameters = {
                map: this.materialTextures.texture || null,
                normalMap: this.materialTextures.normal || null,
                roughnessMap: this.materialTextures.roughness || null,
                displacementMap: this.materialTextures.diffuse || null,
                displacementScale:0,
                roughness: 0.7,
            };
            if (this.materialData.emissiveColor) {
                parameters.emissive = this.materialData.emissiveColor || '#ffffff'; 
                parameters.emissiveIntensity = 0.35;
            }
            this.bodyMaterial = new MeshStandardMaterial(parameters);
        }

        return this.bodyMaterial;
    }

    protected getSecondMaterial(): MeshStandardMaterial {
        if (this.material2Data && this.material2Textures) {
            if (!this.bodySecondMaterial) {
                let parameters: MeshStandardMaterialParameters;

                parameters = {
                    color: this.material2Data.color || '#ffffff',
                    map: this.material2Textures.texture || null,
                    normalMap: this.material2Textures.normal || null,
                    roughnessMap: this.material2Textures.roughness || null,
                    metalness: 0.1,
                    roughness: 0.1,
                    envMapIntensity:4,
                };
                if (this.material2Data.emissiveColor) {
                    parameters.emissive = this.material2Data.emissiveColor;
                    parameters.emissiveIntensity = 0.5;
                }
                this.bodySecondMaterial = new MeshStandardMaterial(parameters);
            }

            return this.bodySecondMaterial;
        }

        return this.getBodyMaterial();

    }

    protected scaleThreeModel() {
    }

    protected initMaterialData(): IMaterialData {
        return this.service.getMaterial(this.getLevel(), this.facadeMaterialData.material);
    }
    protected initMaterial2Data(): IMaterialData | undefined {
        if (this.facadeMaterialData.material2) {
            return this.service.getMaterial(this.getLevel(), this.facadeMaterialData.material2);
        }
        return undefined;
    }
    protected initGlassMaterialData(): IMaterialData {
        return this.service.getGlassMaterial(this.saveData.glass, this.facadeData.id);
    }

    protected loadTextures(materialData: IMaterialData): IMaterialTextures {
        switch (this.getModelType()) {
            case FACADE_MODEL_TYPE_PLANE:
                return this.loadShapeTextures(materialData);
            default:
                return this.service.loadMaterialTextures(materialData.id, materialData.textures);
        }
    }

    protected loadShapeTextures(initMaterialData: IMaterialData): IMaterialTextures {
        if (!initMaterialData.textures) {
            return this.service.loadMaterialTextures(initMaterialData.id, initMaterialData.textures);
        }
        let materialData: IMaterialData;
        let index: string;

        materialData = CommonHelper.deepCopy(initMaterialData);
        if (materialData.textures) {
            for (index in materialData.textures) {
                if (!materialData.textures.hasOwnProperty(index)) {
                    continue;
                }
                materialData.textures[index].repeat = {
                    x: materialData.textures[index].repeat.x*0.001,
                    y: materialData.textures[index].repeat.x*0.001
                }
            }
        }

        return this.service.loadMaterialTextures(materialData.id, materialData.textures);
    }

    protected loadTexturesEDIT(): IMaterialTextures {
        return this.service.loadMaterialTexturesEDIT();
    }

    protected addToScene() {
        this.unit.view3d.add(this.view3d);
    }

    protected initPosition(): Vector3 {
        let position: Vector3;    
        
        position = new Vector3();
        if (this.saveData.align) {
            switch (this.saveData.align.x) {
                case ALIGN_LEFT:
                    position.x = -this.unit.getCorpusSizes().length/2 + this.getWidth()/2 +
                        KitchenHelper.getGapValue(SIDE_TYPE_LEFT, this.saveData.gap);
                    break;
                case ALIGN_RIGHT:
                    position.x = this.unit.getCorpusSizes().length/2 - this.getWidth()/2 -
                        KitchenHelper.getGapValue(SIDE_TYPE_RIGHT, this.saveData.gap);
                    break;
            }
            switch (this.saveData.align.y) {
                case ALIGN_BOTTOM:
                    position.y = -this.unit.getCorpusSizes().height/2 + this.getHeight()/2 +
                        KitchenHelper.getGapValue(SIDE_TYPE_BOTTOM, this.saveData.gap);
                    break;
                case ALIGN_TOP:
                    position.y = this.unit.getCorpusSizes().height/2 - this.getHeight()/2 -
                        KitchenHelper.getGapValue(SIDE_TYPE_TOP, this.saveData.gap);
                    break;
            }
            position.z = this.unit.getCorpusPosition().z + this.unit.getCorpusSizes().width/2;  
        }

        if(this.saveData.functionalType?.toLowerCase().indexOf('боковая') !== -1) {
            position.z = this.unit.getCorpusPosition().z;
        }

        if (this.saveData.margin) {
            position.x += this.saveData.margin.x;
            position.y += this.saveData.margin.y;
            position.z += this.saveData.margin.z;
        }
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined) {
                position.x = KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.x,
                    this.unit.getCorpusSizes().length,
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.y !== undefined) {
                position.y = KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.y,
                    this.unit.getCorpusSizes().height,
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.z !== undefined) {
                position.z = KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.z,
                    this.unit.getCorpusSizes().width,
                    this.service.getDataForSizeByParent()
                );
            }
        }

        return position;
    }

    protected initRotation(): Euler {
        let rotation: Euler;

        rotation = new Euler();
        if (this.saveData.rotation) {
            if (this.saveData.rotation.x !== undefined && !isNaN(+this.saveData.rotation.x)) {
                rotation.x = +this.saveData.rotation.x;
            }
            if (this.saveData.rotation.y !== undefined && !isNaN(+this.saveData.rotation.y)) {
                rotation.y = +this.saveData.rotation.y;
            }
            if (this.saveData.rotation.z !== undefined && !isNaN(+this.saveData.rotation.z)) {
                rotation.z = +this.saveData.rotation.z;
            }
        }
        if (this.isFlipY()) {
            rotation.z = Math.PI;
        }

        if (this.saveData.initRotation) {
            if (this.saveData.initRotation.x !== undefined &&
                !isNaN(+this.saveData.initRotation.x)) {
                rotation.x = +this.saveData.initRotation.x;
            }
            if (this.saveData.initRotation.y !== undefined &&
                !isNaN(+this.saveData.initRotation.y)) {
                rotation.y = +this.saveData.initRotation.y;
            }
            if (this.saveData.initRotation.z !== undefined &&
                !isNaN(+this.saveData.initRotation.z)) {
                rotation.z = +this.saveData.initRotation.z;
            }
        }

        return rotation;
    }

    protected createShape() {
       throw new Error('replace-ThreeFacade-createShape')
    }

    protected createDummy() {
       throw new Error('replace-ThreeFacade-createDummy')
    }

    protected createThreeModel() {
        this.threeModel.name = 'threeModel';
        this.threeModel.matrixAutoUpdate = false;
        this.view3d.add(this.threeModel);
        if (this.saveData.modelType === FACADE_MODEL_TYPE_PLANE || !this.threeModelData) {
            this.addPlaneThreeModel();
        } else {
            this.service.loadFacadeThreeModel(this);
        }
    }

    protected addPlaneThreeModel() {
        let body: Mesh;
        let extrudeSettings;

        extrudeSettings = {
            steps: 1,
            depth: this.getDepth(),
            bevelEnabled: false,
        };
        body = new Mesh(new ExtrudeGeometry(this.shape, extrudeSettings), this.getBodyMaterial());
        body.castShadow = true;
        body.name = 'planeBody';
        body.matrixAutoUpdate = false;
        this.threeModel.add(body);
        this.afterLoadThreeModel();
    }

    protected initFacadeMaterialData(facadeMaterialId?: string): IFacadeMaterialData {
        let corpusFacadeMaterial: IFacadeMaterialData | undefined;

        switch (this.getFacadeMaterialType()) {
            case FACADE_MATERIAL_TYPE_CORPUS_MATERIAL:
                corpusFacadeMaterial = this.service.getCorpusFacadeMaterial(facadeMaterialId);
                if (corpusFacadeMaterial) {
                    return corpusFacadeMaterial;
                }
                break;
            default:
                return this.service.getFacadeMaterial(this.getLevel(), facadeMaterialId);
        }

        return this.service.getFacadeMaterial(this.getLevel(), facadeMaterialId);
    }

    protected initFacadeData(): IFacadeData {
        let facadeData: IFacadeData | undefined;

        facadeData = this.service.getFacadeData(this.facadeMaterialData.facade);
        if (!facadeData) {
            facadeData = this.service.getDefaultFacadeData(this.getLevel());
        }

        return facadeData;
    }

    protected initThreeModel(): Group {
        let threeModel: Group;

        threeModel = new Group();
        threeModel.userData.notSwitchView = true;

        return threeModel;
    }

    protected getThreeModelData(): IFacadeModelData | undefined {
        return this.service.getFacadeThreeModel(
            this.facadeData,
            {
                height: this.getHeight(),
                width: this.getWidth(),
                depth: this.getDepth(),
                type: this.getModelType()
            },
            {
                height: this.HEIGHT_GAP,
                width: this.WIDTH_GAP
            });
    }

    protected initThreeUnitSaveData(saveData: ISaveFacadeData): ISaveFacadeData {
        super.initThreeUnitSaveData(saveData);
        saveData.sizes = KitchenHelper.calculateFacadeSizes(
            saveData.initSizes,
            this.unit.getCorpusSizes(),
            this.service,
            saveData.gap
        );
        if (!saveData.align) {
            saveData.align = {x: ALIGN_CENTER, y: ALIGN_BOTTOM};
        }

        return saveData;
    }
}