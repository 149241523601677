import React, {ReactNode} from 'react';
import {IClassNameProps} from '@bem-react/core';
import './CreateObjectOptions-PriceAmount.css';
import {useTranslation} from 'react-i18next';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {CurrencyHelper} from '../../../../../domain/CurrencyHelper/CurrencyHelper';
import {cnCreateObjectOptions} from '../CreateObjectOptions';
import {IModulePriceData} from '../../../../../common-code/interfaces/catalog/IModulePriceData';
import {IStocksInWay} from '../../../../../common-code/interfaces/catalog/IStocksInWay';
import {CommonHelper} from 'common-code';

export interface ICreateObjectOptionsPriceAmountProps extends IClassNameProps {
    objectData: ICreateObjectData;
    service: KitchenService;
    modulePrice: IModulePriceData | undefined;
}

export const CreateObjectOptionsPriceAmount: React.FC<ICreateObjectOptionsPriceAmountProps> =
    ({
         className,
         objectData,
         modulePrice
     }) => {

        const {t} = useTranslation();

        const renderStockInWay = (stocksInWay?: IStocksInWay): ReactNode[] | null => {
            if (!stocksInWay) {
                return null;
            }
            let renderItems: ReactNode[] = [];
            let date: string;

            for (date in stocksInWay) {
                renderItems.push(<div className={cnCreateObjectOptions('StockInWayValueItem')}>
                    <div className={cnCreateObjectOptions('StockInWayDate')}>{CommonHelper.getFormatDate(stocksInWay[date].date)}:</div>
                    <div className={cnCreateObjectOptions('StockInWayCount')}>{stocksInWay[date].amount} {t('шт')}.</div>
                </div>)
            }

            return renderItems;

        }

        if (objectData.notPrice) {
            return null;
        }
        if (!modulePrice) {
            return null;
        }

        return (
            <div className={cnCreateObjectOptions('PriceAmount', [className])}>
                <div className={cnCreateObjectOptions('Price')}>
                    <div className={cnCreateObjectOptions('PriceTitle')}>{t('Цена')}:</div>
                    <div className={cnCreateObjectOptions('PriceValue')}>{CurrencyHelper.formatValue(modulePrice.price)}</div>
                </div>
                <div className={cnCreateObjectOptions('Amount')}>
                    <div className={cnCreateObjectOptions('AmountTitle')}>{t('Количество на складе')}:</div>
                    <div className={cnCreateObjectOptions('AmountValue', {zero: !modulePrice.stock})}>
                        {modulePrice.stock ? modulePrice.stock + ' ' + t('шт') + '.' : t('нет в наличии')}
                    </div>
                </div>
                {
                    modulePrice.stocksInWay ? <div className={cnCreateObjectOptions('StockInWay')}>
                        <div className={cnCreateObjectOptions('StockInWayTitle')}>{t('* Поступление на')}:</div>
                        <div className={cnCreateObjectOptions('StockInWayValue')}>
                            {renderStockInWay(modulePrice.stocksInWay)}
                        </div>
                    </div> : null
                }
            </div>
        );
    }
