import React from 'react'
import { cn } from '@bem-react/classname'
import { IClassNameProps } from '@bem-react/core'
import { IMaterialData } from 'common-code/lib/interfaces/materials/IMaterialData'
import { Button, cnButton } from '../../../../components/Button'
import { Svg } from '../../../../components/Svg'

import { useSelector } from 'react-redux'
import { AppState } from '../../../../../redux/AppStore'
import { cnEditMaterialsList } from '../MaterialsList'
import { TMaterialEditMode } from '../../../../../../common-code/types/TMaterialEditMode'
import './MaterialItem.css'

export const cnMaterialItem = cn('MaterialItem')

interface IMaterialItemProps extends IClassNameProps {
    prevTitle?: string
    material: IMaterialData
    index: number
    handleEditMaterial: (
        material: IMaterialData,
        type: TMaterialEditMode
    ) => void
    saveMaterial: (
        materialListNew: IMaterialData[],
        type: TMaterialEditMode
    ) => void
    type: TMaterialEditMode
}

export const MaterialItem = ({
    prevTitle,
    material,
    saveMaterial,
    index,
    type,
    handleEditMaterial,
}: IMaterialItemProps) => {
    const [hoverItem, setHoverItem] = React.useState<boolean>(false)

    const stateMaterials: IMaterialData[] = useSelector(
        (state: AppState) => state.wizard[type]
    )

    const deleteMaterial = async () => {
        if (!window.confirm('Вы уверены, что хотите удалить материал?')) return
        const materialListNew = stateMaterials.filter((m, i) => i !== index)
        saveMaterial(materialListNew, type)
    }

    return (
        <div
            className={cnMaterialItem({ hover: hoverItem, animated: true }, [
                cnEditMaterialsList('Item'),
            ])}
            onMouseEnter={() => {
                setHoverItem(true)
            }}
            onMouseLeave={() => {
                setHoverItem(false)
            }}
        >
            <div className={cnMaterialItem('Wrapper')}>
                <div className={cnMaterialItem('Images')}>
                    {material.image && material.image.length ? (
                        <img
                            className={cnMaterialItem('Image')}
                            src={material.image}
                            alt={material.title}
                        />
                    ) : material.color && material.color.length ? (
                        <div
                            className={cnMaterialItem('Color')}
                            style={{ backgroundColor: material.color }}
                        />
                    ) : (
                        <Svg
                            className={cnMaterialItem('Icon')}
                            icon={'no-photo'}
                            label={material.title}
                        />
                    )}
                </div>
                <div className={cnMaterialItem('Name')}>
                    {prevTitle
                        ? `${prevTitle} ${material.title}`
                        : material.title}
                </div>

                <div className={cnMaterialItem('Buttons')}>
                    <Button
                        className={cnMaterialItem(cnButton())}
                        type={'button'}
                        text={'Редактировать'}
                        mods={{
                            mods: {
                                bkg: 'white',
                                color: 'lightGreen',
                                fill: 'lightGreen',
                                border: 'lightGreen',
                            },
                            hover: {
                                mods: {
                                    bkg: 'lightGreen',
                                    color: 'white',
                                    fill: 'white',
                                    border: 'green',
                                },
                            },
                        }}
                        callBack={() => handleEditMaterial(material, type)}
                    />
                    <Button
                        className={cnMaterialItem(cnButton())}
                        type={'button'}
                        text={'Удалить'}
                        mods={{
                            mods: {
                                bkg: 'white',
                                color: 'lightGreen',
                                fill: 'lightGreen',
                                border: 'lightGreen',
                            },
                            hover: {
                                mods: {
                                    bkg: 'lightGreen',
                                    color: 'white',
                                    fill: 'white',
                                    border: 'green',
                                },
                            },
                        }}
                        callBack={deleteMaterial}
                    />
                </div>
            </div>
        </div>
    )
}
