import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeRoom} from '../../rooms/ThreeRoom/ThreeRoom';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {
    SETTING_GROUP_GENERAL
} from '../../../../../constants';
import {
    FACADE_CALCULATE_SELF_AMOUNT,
    OPTION_TYPE_CHECKBOX,
    OPTION_TYPE_NUMBER,
    OPTION_TYPE_RANGE,
    OPTION_TYPE_TEXT
} from '../../../../../../common-code/constants';
import {ISaveConstructiveData} from '../../../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {CommonHelper} from 'common-code';
import {ISettingGroup} from '../../../../../interfaces/settingData/ISettingGroup';
import {ICreateObjectData} from '../../../../../../common-code/interfaces/createData/ICreateObjectData';
import {IOptionGroup} from '../../../../../../common-code/interfaces/option/IOptionGroup';
import {IOption} from '../../../../../../common-code/interfaces/option/IOption';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';
import {IOptionCheckbox} from '../../../../../../common-code/interfaces/option/IOptionCheckbox';
import {ThreeFacade} from '../../details/ThreeFacade/ThreeFacade';

export class ThreeConstructive extends ThreeUnit {
    service: KitchenService;
    room: ThreeRoom;
    saveData: ISaveConstructiveData;

    constructor(data: ISaveConstructiveData, room: ThreeRoom) {
        super(data, room.getService());
        this.room = room;
        this.service = room.getService();
        this.saveData = data;
    }

    public getData(): ISaveConstructiveData {
        let data: ISaveConstructiveData = CommonHelper.deepCopy(super.getData());
        data.notPrice = true;

        return data;
    }

    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup};
        let createObjectData: ICreateObjectData | undefined;
        let index: string;
        let index2: string;
        let optionGroup: IOptionGroup;
        let optionItem: IOption | undefined;

        groups = super.getSettingsGroups();
        createObjectData = this.service.getCreateConstructiveByUid(this.getUid());
        if (createObjectData) {
            for (index in createObjectData.options) {
                if (CommonHelper.instanceOfIOption(createObjectData.options[index])) {
                    optionItem = this.getDynamicOption(createObjectData.options[index] as IOption);
                    if (optionItem) {
                        (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other.push(optionItem);
                    }
                } else if (CommonHelper.instanceOfIOptionGroup(createObjectData.options[index])) {
                    optionGroup = createObjectData.options[index] as IOptionGroup;
                    for (index2 in optionGroup.options) {
                        optionItem = this.getDynamicOption(optionGroup.options[index2] as IOption, optionGroup.id);
                        if (optionItem) {
                            (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other.push(optionItem);
                        }
                    }
                }
            }
        }

        return groups;
    }

    public getSelfCalculateFacades(): ThreeFacade[] {
        let facades: ThreeFacade[] = []
        let facade: ThreeFacade

        if (this.facades) {
            for (facade of this.facades) {
                if (
                    facade.getCalculateType() === FACADE_CALCULATE_SELF_AMOUNT
                ) {
                    facades.push(facade)
                }
            }
        }

        return facades;
    }

    protected getDynamicOption(option: IOption, groupId?: string): IOption | undefined {
        let optionItem: IOption | undefined;

        switch (option.type) {
            case OPTION_TYPE_NUMBER:
            case OPTION_TYPE_RANGE:
                optionItem = CommonHelper.deepCopy(option);

                if (optionItem) {
                    optionItem.defaultValue = optionItem.value = this.getNumberSaveDataValue(option.id, groupId);
                }
                break;
            case OPTION_TYPE_TEXT:
                optionItem = CommonHelper.deepCopy(option);
                if (optionItem) {
                    optionItem.defaultValue = optionItem.value = this.getTextSaveDataValue(option.id, groupId);
                }
                break;
            case OPTION_TYPE_CHECKBOX:
                optionItem = CommonHelper.deepCopy(option) as IOptionCheckbox;
                if (optionItem) {
                    optionItem.defaultValue = optionItem.value = this.getBooleanSaveDataValue(option.id);
                }
                break;
        }
        if (optionItem && groupId) {
            optionItem.groupId = groupId;
        }

        return optionItem;
    }
}