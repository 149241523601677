import React, { ReactNode, useState } from "react";
import { IClassNameProps } from "@bem-react/core";
import "./Wizard-VerticalMenu.css";
import { cn } from "@bem-react/classname";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { Svg } from "../../../components/Svg";
import { TWizardUIOptions } from "../../../../types/TWizardUIOptions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import {
  ADD_MESSAGE,
  HIDE_LOADING,
  KITCHEN_SIZES_TYPE_ALL,
  KITCHEN_SIZES_TYPE_NONE,
  KITCHEN_VIEW_SKETCH,
  KITCHEN_VIEW_VISUAL,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
  SHOW_LOADING,
} from "../../../../constants";
import { Popup } from "../../../components/Popup";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";
import { SetProjectOwner } from "../../SetProjectOwner/SetProjectOwner";
import { TUserRole } from "../../../../../common-code/types/TUserRole";
import { ROLE_ADMIN, ROLE_MANAGER } from "../../../../../common-code/constants";
import { Link } from "../../../components/Link/Link";
import { IProjectData } from "../../../../../common-code/interfaces/project/IProjectData";
import { useUserRole } from "../../../../hooks/useUserRole";

export const cnWizardVerticalMenu = cn("WizardVerticalMenu");
export interface IWizardVerticalMenuProps extends IClassNameProps {
  service: KitchenService;
}

export const WizardVerticalMenu: React.FC<IWizardVerticalMenuProps> = ({
  className,
  service,
}) => {
  const kitchenOptions: TWizardUIOptions = useSelector(
    (state: AppState) => state.wizard.options
  );
  const [hoverIcon, setHoverIcon] = useState<string | null>(null);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode | undefined>(
    undefined
  );
  const userRole: TUserRole = useUserRole();
  const dispatch = useDispatch();
  const projectData: IProjectData = useSelector(
    (state: AppState) => state.wizard.project
  );

  const toggleSizes = () => {
    if (kitchenOptions.sizesType === KITCHEN_SIZES_TYPE_NONE) {
      service.setSizeType(KITCHEN_SIZES_TYPE_ALL);
    } else {
      service.setSizeType(KITCHEN_SIZES_TYPE_NONE);
    }
  };

  const changeKitchenView = () => {
    if (kitchenOptions.viewType === KITCHEN_VIEW_VISUAL) {
      service.setViewType(KITCHEN_VIEW_SKETCH);
    } else {
      service.setViewType(KITCHEN_VIEW_VISUAL);
    }
  };

  const toggleRoomVisible = () => {
    service.toggleRoomVisible();
  };

  const showAll = () => {
    service.showAll();
  };

  const createPdf = () => {
    dispatch({ type: SHOW_LOADING });
    service
      .createPdf()
      .then((pdfUrl) => {
        dispatch({ type: HIDE_LOADING });
        setShowModal(true);
        setModalContent(
          <div className={cnWizardVerticalMenu("Modal")}>
            <div className={cnWizardVerticalMenu("ModalTitle")}>
              {t("PDF файл успешно сформирован, открыть вы можете по ссылке")}
            </div>
            <Link
              className={cnWizardVerticalMenu("Link")}
              href={pdfUrl}
              target={"_blank"}
              type={"default"}
            >
              {t("Открыть")}
            </Link>
          </div>
        );
      })
      .catch(() => {
        dispatch({ type: HIDE_LOADING });
        dispatch({
          type: ADD_MESSAGE,
          payload: {
            type: MESSAGE_TYPE_ERROR,
            message: t("При формировании PDF проекта произошла ошибка"),
            autoClose: true,
          },
        });
      });
  };

  const createWord = () => {
    dispatch({ type: SHOW_LOADING });
    service
      .createWord()
      .then((wordUrl: string) => {
        dispatch({ type: HIDE_LOADING });
        setShowModal(true);
        setModalContent(
          <div className={cnWizardVerticalMenu("Modal")}>
            <div className={cnWizardVerticalMenu("ModalTitle")}>
              {t("DOCX файл успешно сформирован, открыть вы можете по ссылке")}
            </div>
            <Link
              className={cnWizardVerticalMenu("Link")}
              href={wordUrl}
              target={"_blank"}
              type={"default"}
            >
              {t("Открыть")}
            </Link>
          </div>
        );
      })
      .catch(() => {
        dispatch({ type: HIDE_LOADING });
        dispatch({
          type: ADD_MESSAGE,
          payload: {
            type: MESSAGE_TYPE_ERROR,
            message: t("При формировании DOCX проекта произошла ошибка"),
            autoClose: true,
          },
        });
      });
  };

  const testGltfExport = () => {
    service.gltfTestExport();
  };
  
  const afterSetOwner = (result: boolean) => {
    if (result) {
      dispatch({
        type: ADD_MESSAGE,
        payload: {
          type: MESSAGE_TYPE_SUCCESS,
          message: t("Смена покупателя проекта прошла успешно"),
          autoClose: true,
        },
      });
    } else {
      dispatch({
        type: ADD_MESSAGE,
        payload: {
          type: MESSAGE_TYPE_ERROR,
          message: t("При смене покупателя проекта произошла ошибка"),
        },
      });
    }
    setShowModal(false);
    setModalContent(undefined);
  };

  const projectSetOwner = () => {
    setShowModal(true);
    setModalContent(
      <SetProjectOwner
        service={service}
        className={cnWizardVerticalMenu("SetProjectOwner")}
        projectUserId={projectData.customer?.id}
        successCallBack={() => {
          afterSetOwner(true);
        }}
        errorCallBack={() => {
          afterSetOwner(false);
        }}
      />
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(undefined);
  };


  const shadowSwitch = () => {
    service.shadowSwitch();
  }

  return (
    <div className={cnWizardVerticalMenu({}, [className])}>
      <div
        className={cnWizardVerticalMenu("Action", {
          active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
        })}
        onClick={toggleSizes}
        onMouseEnter={() => {
          setHoverIcon("sizes");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"sizes"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"sizes" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Вкл/выкл размеры")}
        </Popup>
      </div>
      <div
        className={cnWizardVerticalMenu("Action", {
          active: kitchenOptions.viewType === KITCHEN_VIEW_SKETCH,
        })}
        onClick={changeKitchenView}
        onMouseEnter={() => {
          setHoverIcon("lines");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"lines"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.viewType === KITCHEN_VIEW_SKETCH,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"lines" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Вкл/выкл скетч вид")}
        </Popup>
      </div>
      <div
        className={cnWizardVerticalMenu("Action", {
          active: !kitchenOptions.roomVisible,
        })}
        onClick={toggleRoomVisible}
        onMouseEnter={() => {
          setHoverIcon("room");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"hide"}
          className={cnWizardVerticalMenu("Icon", {
            active: !kitchenOptions.roomVisible,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"room" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Вкл/выкл комнату")}
        </Popup>
      </div>
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={showAll}
        onMouseEnter={() => {
          setHoverIcon("showAll");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"kitchen-construct"}
          className={cnWizardVerticalMenu("Icon")}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"showAll" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Показать все")}
        </Popup>
      </div>
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={createPdf}
        onMouseEnter={() => {
          setHoverIcon("createPdf");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg icon={"pdf"} className={cnWizardVerticalMenu("Icon")} />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"createPdf" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Скачать PDF")}
        </Popup>
      </div>
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={createWord}
        onMouseEnter={() => {
          setHoverIcon("createWord");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg icon={"word"} className={cnWizardVerticalMenu("Icon")} />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"createWord" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Скачать WORD")}
        </Popup>
      </div>

      <div 
        className={cnWizardVerticalMenu("Action")}
        onClick={shadowSwitch}
        onMouseEnter={() => {
          setHoverIcon("change");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg icon={"change"} className={cnWizardVerticalMenu("Icon")} />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"change" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Выключить/включить тени")}
        </Popup>
    
      </div>

      <div 
        className={cnWizardVerticalMenu("Action")}
        onClick={testGltfExport}
        onMouseEnter={() => {
          setHoverIcon("3d-model");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg icon={"3d-model"} className={cnWizardVerticalMenu("Icon")} />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"3d-model" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Export glTF")}
        </Popup>
    
      </div>

      {![ROLE_ADMIN, ROLE_MANAGER].includes(userRole) || (
        <div
          className={cnWizardVerticalMenu("Action")}
          onClick={projectSetOwner}
          onMouseEnter={() => {
            setHoverIcon("setOwner");
          }}
          onMouseLeave={() => {
            setHoverIcon(null);
          }}
        >
          <Svg icon={"man"} className={cnWizardVerticalMenu("Icon")} />
          <Popup
            className={cnWizardVerticalMenu("Popup")}
            active={"setOwner" === hoverIcon}
            position={{ x: -40, y: 0 }}
          >
            {t("Сменить пользователя проекта")}
          </Popup>
        </div>
      )}

      <Modal visible={showModal} callBack={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};
