import { IClassNameProps } from '@bem-react/core'
import { useDispatch } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    CHANGE_EDIT_MODE,
    HIDE_LOADING,
    INIT_EDIT_MATERIAL,
    SHOW_LOADING,
} from '../../../../constants'
import { Button, cnButton } from '../../../components/Button'
import { cn } from '@bem-react/classname'
import FormHandle, { INewHandleData } from '../FormHandle/FormHandle'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import { TEditMode } from '../../../../../common-code/types/TEditMode'
import { TMaterialEditMode } from '../../../../../common-code/types/TMaterialEditMode'
import {
    BUTTON_LEFT_ICON_ARROW_LEFT,
    BUTTON_MODE_BLACK_WHITE,
} from '../../../constants'
import './EditHandle.css'

interface IEditHandleProps extends IClassNameProps {
    saveHandle: (
        handleListNew: IHandleData[],
        handleMode: TMaterialEditMode
    ) => Promise<void>
    saveImagePreview: (
        imageNew: File,
        plinthId: string,
        materialMode: TMaterialEditMode
    ) => Promise<string | null>
}

const cnEditHandle = cn('EditHandle')

function EditHandle({ saveHandle, saveImagePreview }: IEditHandleProps) {
    const { t } = useTranslation()
    const isEditMode = useSelector(
        (state: AppState) => state.wizard.editModule.isEditMode
    )
    const handles = useSelector((state: AppState) => state.wizard[isEditMode])
    const dispatch = useDispatch()

    const handleToggleMode = (str: TEditMode) => {
        dispatch({ type: CHANGE_EDIT_MODE, payload: str })
        dispatch({ type: INIT_EDIT_MATERIAL })
    }

    const saveHandlesCollection = async (itemHandle: INewHandleData) => {
        // проверяем корректность заполнения формы
        if (!itemHandle) {
            alert('Необходимо корректно заполнить форму.')
            return
        }
        // если такое название уже есть
        if (
            handles &&
            handles.length > 0 &&
            handles.find((handle: IHandleData) => handle.id === itemHandle.id)
        ) {
            alert(
                'Ручка с такими параметрами уже существует в коллекции. Укажите другое название.'
            )
            return
        }
        dispatch({ type: SHOW_LOADING })
        // сохраняем preview материала
        if (itemHandle.imageNew) {
            const imageUrl = await saveImagePreview(
                itemHandle.imageNew,
                itemHandle.id,
                'handles'
            )
            if (!imageUrl) {
                alert('Ошибка при сохранении превью. Попробуйте еще раз.')
                dispatch({ type: HIDE_LOADING })
                return
            }
            itemHandle.image = imageUrl
            // удаляем ключ imageNew
            delete itemHandle.imageNew
        }

        // сохраняем ручки
        const handleListNew = [...handles, itemHandle]
        saveHandle(handleListNew, 'handles').then(() => {
            handleToggleMode('')
            dispatch({ type: HIDE_LOADING })
        })
    }

    const editHandlesCollection = async (itemHandle: INewHandleData) => {
        // проверяем корректность заполнения формы
        if (!itemHandle) {
            alert('Необходимо корректно заполнить форму.')
            return
        }
        // если такое название уже есть
        if (
            handles.length > 0 &&
            !handles.find((plinth: IHandleData) => plinth.id === itemHandle.id)
        ) {
            alert('Ручки с таким наменованием нет. Укажите другое название.')
            return
        }
        dispatch({ type: SHOW_LOADING })

        // сохраняем preview материала
        if (itemHandle.imageNew) {
            const imageUrl = await saveImagePreview(
                itemHandle.imageNew,
                itemHandle.id,
                'handles'
            )
            if (!imageUrl) {
                alert('Ошибка при сохранении превью. Попробуйте еще раз.')
                dispatch({ type: HIDE_LOADING })
                return
            }
            itemHandle.image = imageUrl
            // удаляем ключ imageNew
            delete itemHandle.imageNew
        }

        // сохраняем ручки
        const handleListNew = handles.map((handle: IHandleData) =>
            handle.id === itemHandle.id ? itemHandle : handle
        )
        saveHandle(handleListNew, 'handles').then(() => {
            handleToggleMode('')
            dispatch({ type: HIDE_LOADING })
        })
    }

    return (
        <>
            <FormHandle
                saveHandlesCollection={saveHandlesCollection}
                editHandlesCollection={editHandlesCollection}
            />

            <div className={cnEditHandle('Buttons-Container')}>
                <Button
                    className={cnEditHandle(cnButton(), { type: 'prev' })}
                    leftIcon={BUTTON_LEFT_ICON_ARROW_LEFT}
                    text={t('Предыдущий шаг') || undefined}
                    callBack={() => handleToggleMode('')}
                    mods={BUTTON_MODE_BLACK_WHITE}
                    type={'button'}
                />
            </div>
        </>
    )
}

export default EditHandle
