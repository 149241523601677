import {ThreeUnit} from "../../ThreeUnit/ThreeUnit";
import SpriteText from "three-spritetext";
import {
  ACTION_COPY,
  ACTION_REPLACE,
  KITCHEN_VIEW_SKETCH,
  KITCHEN_VIEW_VISUAL, SETTING_GROUP_GENERAL,
} from "../../../../../constants";
import {KitchenService} from "../../../../services/KitchenService/KitchenService";
import {
  BoxGeometry,
  Group, Material,
  Mesh,
  MeshBasicMaterial, MeshStandardMaterial,
  Object3D,
  Vector3,
} from "three";
import {i18n} from "../../../../../i18n";
import {
    onAfterRenderTransparentForBack,
    onBeforeRenderTransparentForBack,
} from "../../../../helpers/ThreeHelper/ThreeHelper";
import {ISaveEquipmentData} from "../../../../../../common-code/interfaces/saveData/ISaveEquipmentData";
import {IEquipmentModelData} from "../../../../../../common-code/interfaces/IEquipmentModelData";
import {CommonHelper} from "common-code";
import {IContextIcon} from "../../../../../interfaces/IContextIcon";
import {ICreateObjectData} from "../../../../../../common-code/interfaces/createData/ICreateObjectData";
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {ISettingGroup} from '../../../../../interfaces/settingData/ISettingGroup';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';
import {
    FACADE_CALCULATE_SELF_AMOUNT,
    OPTION_TYPE_TEXT,
    PRICE_CELL_EXTRA_OFFERS
} from '../../../../../../common-code/constants';
import {IOptionText} from '../../../../../../common-code/interfaces/option/IOptionText';
import {IImportOffer} from '../../../../../../common-code/interfaces/api/IImportOffer';
import {IOptionOffers} from '../../../../../../common-code/interfaces/option/IOptionOffers';
import {IProjectOffers} from '../../../../../../common-code/interfaces/project/IProjectOffers';
import {ThreeFacade} from '../../details/ThreeFacade/ThreeFacade';

export class ThreeEquipment extends ThreeUnit {
    saveData: ISaveEquipmentData;
    sticker?: SpriteText;
    threeModelData: IEquipmentModelData;
    threeModel: Group;
    dummy: Mesh;
    bodyMaterial?: MeshStandardMaterial;
    materialData?: IMaterialData;
    materialTextures?: IMaterialTextures;

    constructor(options: ISaveEquipmentData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.dummy = new Mesh();
        this.threeModelData = this.initThreeModelData();
        this.threeModel = new Group();
    }

    public initState(isRebuild?: boolean) {
        this.createDummy();
        this.createThreeModel();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.createSticker();
        super.createView(isRebuild);
    }

    public getData(): ISaveEquipmentData {
        let data: ISaveEquipmentData = CommonHelper.deepCopy(super.getData());
        data.sticker = this.sticker ? this.sticker.text : '';
        data.notPrice = this.calculateNotPrice();
        return data;
    }

    public hideSticker() {
        if (this.sticker) {
            this.sticker.visible = false;
        }
    }

    public showSticker() {
        if (this.sticker) {
            this.sticker.visible = true;
        }
    }

    public getThreeModelId(): string {
        return this.saveData.model;
    }

    public setLoadModel(type: string, details: Object3D[]) {
        let detail: Object3D;
        let newDetail: Object3D;

        for (detail of details) {
            newDetail = detail.clone();
            this.threeModel.add(newDetail);
        }
        this.threeModel.traverse(child => {
            child.matrixAutoUpdate = false;
        });
        this.afterSetLoadModel();
        this.dummy.visible = false;
        // модель масштабируется в ThreeKUnit - createEquipment
        this.scaleThreeModel();
        this.updateViewType();
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
    }

    public setTransparentForBack() {
        this.threeModel.traverse((child) => {
            if (child.userData.notTransparentForBack) {
                return;
            }
            child.userData.parent = this.view3d;
            if (child instanceof Mesh) {
                child.onBeforeRender = onBeforeRenderTransparentForBack;
                child.onAfterRender = onAfterRenderTransparentForBack;
            }
        });
    }

    public scaleThreeModel() {
    // TODO переделать
        // масштабировать модель встраиваемой варочной панели для модулей с одной дверью
        const hob2Index = this.threeModel.children.findIndex(
            (child) =>
                child.name === "Box001_Untitled002" ||
                child.name === "Box001_Untitled001"
        );
        if (hob2Index !== -1) {
            this.threeModel.scale.set(
                this.getWidth() / this.threeModelData.width,
                this.service.getTabletopHeight() / this.threeModelData.height,
                (this.getDepth() - 3 * this.service.getCornerWidth()) /
                this.threeModelData.depth
            );
            this.updateAllMatrices();
            return;
        }
        // не масштабировать модель встраиваемой варочной панели для модулей с двумя дверями или угловых
        const hobIndex = this.threeModel.children.findIndex(
            (child) => child.name === "ChamferBox001002"
        );
        if (hobIndex !== -1) return;

        // масштабировать модель встаиваемой раковины
        const builtInBoxSinkIndex = this.threeModel.children.findIndex(
            (child) => child.name === "Ulgran_U-507-310_&_Frap_F4352-20"
        );
        if (builtInBoxSinkIndex !== -1) {
            this.threeModel.scale.set(
                this.getWidth() / this.threeModelData.width,
                this.getHeight() / this.threeModelData.height + 0.49,
                this.getDepth() / this.threeModelData.depth
            );
            this.updateAllMatrices();
        } else {
            const index2 = this.threeModel.children.findIndex(
                (child) => child.name === "Box018"
            );
            this.threeModel.scale.set(
                this.getWidth() / this.threeModelData.width,
                index2 !== -1
                    ? this.getHeight() / this.threeModelData.height - 1
                    : this.getHeight() / this.threeModelData.height,
                this.getDepth() / this.threeModelData.depth
            );
            this.updateAllMatrices();
        }
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: "ThreeEquipment",
                action: ACTION_COPY,
                actionData: actionData,
                popup: false,
                icon: "copy-object",
                hide: true,
                title: i18n.t("Копировать"),
                sort: 110,
            },
            {
                channelName: "ThreeEquipment",
                action: ACTION_REPLACE,
                actionData: actionData,
                popup: false,
                icon: "replace",
                hide: true,
                title: i18n.t("Заменить"),
                sort: 120,
            },
        ];
        icons = icons.concat(super.getContextIcons());

        return icons;
    }

    public updateViewType() {
        super.updateViewType();
        switch (this.viewType) {
            case KITCHEN_VIEW_SKETCH:
                this.showSticker();
                break;
            case KITCHEN_VIEW_VISUAL:
            default:
                this.hideSticker();
                break;
        }
    }

    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup};
        let generalGroup: ISettingGroup | undefined;
        let generalGroupData: ISettingGroupGeneral | undefined;

        groups = super.getSettingsGroups();
        generalGroup = groups[SETTING_GROUP_GENERAL];
        if (generalGroup && generalGroup.data) {
            generalGroupData = generalGroup.data as ISettingGroupGeneral;
        }
        if (generalGroupData) {
            generalGroupData.other.push({
                id: 'sticker',
                sort: 0,
                title: i18n.t('Стикер'),
                type: OPTION_TYPE_TEXT,
                value: this.getStickerText(),
                changeOnBlur: true
            } as IOptionText)
        }

        return groups;
    }

    public getStickerText(): string {
        return this.saveData.sticker || '';
    }

    public getBodyMaterial() {
        let bodyMaterialId: string | undefined;

        bodyMaterialId = this.getBodyMaterialId();
        if (!bodyMaterialId) {
            this.bodyMaterial = undefined;
            return undefined;
        }
        if (this.materialData && this.materialData.id === bodyMaterialId && this.bodyMaterial) {
            return this.bodyMaterial;
        }
        this.materialData = this.service.getMaterialById(bodyMaterialId);
        this.materialTextures = undefined;
        if (this.materialData) {
            this.materialTextures = this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
            this.bodyMaterial = new MeshStandardMaterial({
                color: this.materialData.color || undefined,
                map: this.materialTextures?.texture || null,
                normalMap: this.materialTextures?.normal || null,
                roughnessMap: this.materialTextures?.roughness || null,
            });
        }

        return this.bodyMaterial;
    }

    public setExtraOffer(offer: IImportOffer, count: number, once?: boolean) {
        super.setExtraOffer(offer, count, once);
        this.loadCustomMaterials();
    }

    public getSelfCalculateFacades(): ThreeFacade[] {
        let facades: ThreeFacade[] = []
        let facade: ThreeFacade

        if (this.facades) {
            for (facade of this.facades) {
                if (
                    facade.getCalculateType() === FACADE_CALCULATE_SELF_AMOUNT
                ) {
                    facades.push(facade)
                }
            }
        }

        return facades;
    }

    protected getBodyMaterialId(): string | undefined {
        let index: string;
        let offer: IImportOffer | undefined;

        if (this.extraOffers) {
            for (index in this.extraOffers) {
                offer = this.extraOffers[index].offer;
                if (offer && offer.corpusMaterial) {
                    return offer.corpusMaterial;
                }
            }
        }

        return undefined;
    }

    protected afterSetLoadModel() {
        this.loadCustomMaterials();
    }

    protected initExtraOffers() {
        let optionOffers: IOptionOffers;

        super.initExtraOffers();
        if (this.extraOffers || !this.saveData.offers) {
            return;
        }
        optionOffers = this.saveData.offers;
        if (optionOffers.initSearch && optionOffers.initFilter && optionOffers.initFilter.modelId) {
            this.service.searchOffers('', optionOffers.initFilter).then((offers) => {
                let externalId: 'externalGuid' | 'vendorCode';
                let extraOffers: IProjectOffers = {};

                externalId = this.service.getOfferExternalId();
                if (offers && offers[0]) {
                    extraOffers[offers[0][externalId]] = {
                        id: offers[0][externalId],
                        count: 1,
                        offer: offers[0],
                        unitId: this.getId(),
                        cell: PRICE_CELL_EXTRA_OFFERS,
                    }
                }
                this.extraOffers = extraOffers;
                this.loadCustomMaterials();
                this.loadExtraOffersPrices();
            })
        }
    }

    protected loadCustomMaterials() {
        let material: Material;
        let bodyMaterial: MeshStandardMaterial | undefined;

        bodyMaterial = this.getBodyMaterial();
        if (!bodyMaterial) {
            return;
        }
        this.threeModel.traverse(child => {
            if (child.name.includes('body') && child instanceof Mesh) {
                console.log('child.material')
                if (Array.isArray(child.material)) {
                    for (material of child.material) {
                        material.dispose();
                    }
                } else {
                    if (child.material instanceof MeshStandardMaterial) {
                        child.material.dispose();
                    }
                }
                child.material = bodyMaterial;
                child.material.needsUpdate = true;
            }
        });
    }

    protected initThreeModelData(): IEquipmentModelData {
        return this.service.getEquipmentModel(this);
    }

    protected calculateNotPrice(): boolean {
        const createData: ICreateObjectData | undefined =
            this.service.getCreateUnitByUid(this.saveData.uid);

        if (createData && createData.notPrice !== undefined) {
            return createData.notPrice;
        }

        return false;
    }

    protected createDummy() {
        let geometry;
        let coverPoints: Vector3[];

        geometry = new BoxGeometry(
            this.getWidth(),
            this.getHeight(),
            this.getDepth()
        );
        this.dummy = new Mesh(
            geometry,
            new MeshBasicMaterial({
                color: '#757575',
                wireframe: true,
            })
        );
        coverPoints = [
            new Vector3(
                -this.getWidth() / 2,
                -this.getHeight() / 2,
                -this.getDepth() / 2
            ),
            new Vector3(
                this.getWidth() / 2,
                this.getHeight() / 2,
                this.getDepth() / 2
            ),
        ];
        this.view3d.add(this.dummy);
        this.addCoverPoints(coverPoints);
    }

    protected createThreeModel() {
        this.threeModel.name = "threeModel";
        this.threeModel.matrixAutoUpdate = false;
        this.view3d.add(this.threeModel);
        this.service.loadEquipmentThreeModel(this);
    }

    protected createSticker() {
        if (this.sticker) {
            this.removeSticker();
        }
        if (!this.saveData.sticker || !this.saveData.sticker.length) {
            return;
        }
        this.sticker = new SpriteText();
        this.sticker.textHeight = 120;
        this.sticker.color = "black";
        this.sticker.backgroundColor = "white";
        this.sticker.fontWeight = "bold";
        this.sticker.padding = 5;
        this.sticker.borderRadius = 3;
        this.sticker.visible =
            this.service.getOptions().viewType === KITCHEN_VIEW_SKETCH;
        this.sticker.material.depthTest = false;
        this.sticker.renderOrder = 4;
        this.sticker.text = this.saveData.sticker;
        this.view3d.add(this.sticker);
    }

    protected removeSticker() {
        if (this.sticker) {
            this.view3d.remove(this.sticker);
            this.sticker = undefined;
        }
    }

    public isEquipment(): boolean {
        return true;
    }
}