import {ThreeBuiltInEquipment} from '../ThreeBuiltInEquipment';
import {Box3, Object3D, Vector3} from 'three';

export class ThreeBuiltinExtractEquipment extends ThreeBuiltInEquipment {
    
    public setLoadModel(type: string, details: Object3D[]) {
        super.setLoadModel(type, details);
        let child: Object3D;

        for (child of this.threeModel.children) {
            child.name = `Hood_${Math.random().toString(36).slice(2)}`    
        }
    }

    protected initPosition(): Vector3 {
        let cover: Box3;
        let initPosition: Vector3;

        initPosition = new Vector3();

        cover = this.unit.getCoverBox()
        // initPosition.y = cover.max.y - this.getHeight()/2;
        // модель вытяжки позиционируется в соответствии с минимумом bounding box по оси y
        initPosition.y = cover.min.y
        

        return initPosition;
    }
}