import React, { useMemo, useState } from 'react'
import { IClassNameProps } from '@bem-react/core'
import axios from 'axios'
import { useEffectDidMount } from '../../../../hooks/useEffectDidMount'
import { cn } from '@bem-react/classname'
import { ICollectionDto } from '../../../../../common-code/interfaces/ICollectionDto'
import { GalleryCarousel } from '../../../components/GalleryCarousel/GalleryCarousel'
import { TImageData } from '../../../../../common-code/types/TImageData'
import { CollectionDetailDescription } from '../../CollectionDetail/Description/CollectionDetail-Description'
import { IMaterialData } from 'common-code/lib/interfaces/materials/IMaterialData'
import { IFacadeData } from '../../../../../common-code/interfaces/materials/IFacadeData'
import { IFacadeMaterialData } from 'common-code/lib/interfaces/materials/IFacadeMaterialData'
import MaterialsList from '../MaterialsList/MaterialsList'
import {
    CHANGE_EDIT_MODE,
    CHANGE_ITEM_FACADE,
    CHANGE_ITEM_FACADE_MATERIAL,
    CHANGE_ITEM_HANDLE,
    CHANGE_ITEM_MATERIAL,
    CHANGE_ITEM_PLINTH,
} from '../../../../constants'
import { useDispatch } from 'react-redux'
import { IPlinthData } from '../../../../../common-code/interfaces/materials/IPlinthData'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import EditTechnologMap from '../EditTechnologMap/EditTechnologMap'
import { ITechnologMap } from '../../../../../common-code/interfaces/ITechnologMap'
import { TMaterialEditMode } from '../../../../../common-code/types/TMaterialEditMode'
import { useUrlSearchParam } from '../../../../hooks/useSearchParams'
import {
    EDIT_MODE_CORPUS_MATERIALS,
    EDIT_MODE_FACADE_MATERIALS,
    EDIT_MODE_FACADES,
    EDIT_MODE_HANDLES,
    EDIT_MODE_MATERIALS,
    EDIT_MODE_PLINTHS,
} from 'common-code/lib/constants'
import './WizardCollection.css'

export const cnWizardEditCollection = cn('WizardEditCollection')

export const materialsGroups: Record<TMaterialEditMode, string> = {
    [EDIT_MODE_MATERIALS]: 'Редактирование библиотеки материалов',
    [EDIT_MODE_CORPUS_MATERIALS]: 'Редактирование материалов корпусов',
    [EDIT_MODE_FACADE_MATERIALS]: 'Редактирование материалов фасадов',
    [EDIT_MODE_FACADES]: 'Редактирование библиотеки фасадов',
    [EDIT_MODE_PLINTHS]: 'Редактирование библиотеки цоколей',
    [EDIT_MODE_HANDLES]: 'Редактирование библиотеки ручек',
}

export interface IWizardCollectionProps extends IClassNameProps {
    saveMaterial: (
        materialListNew: IMaterialData[],
        type: TMaterialEditMode
    ) => void
}

export const WizardCollection: React.FC<IWizardCollectionProps> = ({
    saveMaterial,
}: IWizardCollectionProps) => {
    const dispatch = useDispatch()
    const isEditMode = useSelector(
        (state: AppState) => state.wizard.editModule.isEditMode
    )
    const materialsMode = useSelector(
        (state: AppState) => state.wizard.editMaterial.materialsMode
    )
    const [collection, setCollection] = useState<ICollectionDto | undefined>(
        undefined
    )
    const xmlId = useUrlSearchParam('xmlId')

    useEffectDidMount(() => {
        axios
            .get('/api/collection/' + xmlId)
            .then((response) => {
                if (response.data) {
                    setCollection(response.data)
                }
            })
            .catch(() => {})
    })
    const collectionImages: TImageData[] = useMemo(() => {
        return collection ? collection.images : []
    }, [collection])

    const actionType = (type: TMaterialEditMode) => {
        switch (type) {
            case EDIT_MODE_MATERIALS:
            case EDIT_MODE_CORPUS_MATERIALS:
                return CHANGE_ITEM_MATERIAL
            case EDIT_MODE_FACADE_MATERIALS:
                return CHANGE_ITEM_FACADE_MATERIAL
            case EDIT_MODE_FACADES:
                return CHANGE_ITEM_FACADE
            case EDIT_MODE_PLINTHS:
                return CHANGE_ITEM_PLINTH
            case EDIT_MODE_HANDLES:
                return CHANGE_ITEM_HANDLE
        }
    }

    // редактирование материала коллекции
    const handleEditMaterial = (
        item: IMaterialData | IFacadeData | IFacadeMaterialData | IPlinthData,
        type: TMaterialEditMode
    ) => {
        dispatch({ type: CHANGE_EDIT_MODE, payload: type })
        dispatch({ type: actionType(type), payload: item })
    }
    const technologMaps: Record<string, ITechnologMap> = useSelector(
        (state: AppState) => state.wizard.technologMaps
    )

    return (
        <>
            <div
                className={cnWizardEditCollection(
                    materialsMode ? 'Materials' : ''
                )}
                style={{ display: isEditMode ? 'none' : 'block' }}
            >
                {technologMaps &&
                    !materialsMode &&
                    Object.keys(technologMaps)?.length &&
                    Object.entries(technologMaps).map((map) => (
                        <EditTechnologMap key={map[0]} map={map} />
                    ))}
                <div className={cnWizardEditCollection('Materials-Groups')}>
                    {Object.entries(materialsGroups).map(([key, value]) => (
                        <MaterialsList
                            key={key}
                            saveMaterial={saveMaterial}
                            materialGroup={[
                                key as TMaterialEditMode,
                                value as string,
                            ]}
                            handleEditMaterial={handleEditMaterial}
                        />
                    ))}
                </div>
                {collection && !materialsMode && (
                    <div className={cnWizardEditCollection('Container')}>
                        <div className={cnWizardEditCollection('Slider')}>
                            <GalleryCarousel images={collectionImages} />
                        </div>
                        <CollectionDetailDescription collection={collection} />
                    </div>
                )}
            </div>
        </>
    )
}
