import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form';
import InputNumber from '../../components/InputNumber';
import { Tumbler } from '../../../../../components/Tumbler/Tumbler';
import { cnCreateForm } from '../../FormModule';
import { IFormInput } from '../../initialValue';


function Sizes({classNameValue}: {classNameValue: string}) {
  const {  getValues,setValue } = useFormContext<IFormInput>();
  const [enabled, setEnabled] = React.useState<boolean>(!!getValues("heightText") || !!getValues("widthText") || !!getValues("depthText"));


  const isFacadeUnit: boolean = useMemo(
    () => classNameValue.includes('FacadeUnit'),
    [classNameValue]
  );


  React.useEffect(() => {
    setValue("isSizes", enabled || isFacadeUnit);
    // eslint-disable-next-line
  }, [enabled]);

  
  return (
    <>
      {!isFacadeUnit && <Tumbler
          className={cnCreateForm('Tumbler')}
          checked={enabled}
          callBack={(e) => setEnabled(e.target.checked)}
          label="Указать размеры модуля"
      />}
      {(enabled || isFacadeUnit) && 
        <>
          <InputNumber  min={2} max={2000} label="Ширина модуля" name="widthText" required={isFacadeUnit}/>
          <InputNumber  min={2} max={3000} label="Высота модуля" name="heightText" required={isFacadeUnit}/>
          <InputNumber  min={2} max={2000} label="Глубина модуля" name="depthText" required={isFacadeUnit}/>
        </>
      }
    </>
  )
}

export default Sizes