import {IAppConfig} from '../../../interfaces/config/IAppConfig';
import {ICreateGroup} from '../../../interfaces/createData/ICreateGroup';
import {IUnitKits} from '../../../interfaces/catalog/IUnitKits';
import {IUnitKitPrices} from '../../../interfaces/catalog/IUnitKitPrices';
import {ICorpusMaterials} from '../../../interfaces/materials/ICorpusMaterials';
import {IFacadeMaterials} from '../../../interfaces/materials/IFacadeMaterials';
import {IImportOffer} from '../../../interfaces/api/IImportOffer';
import {ICheckCatalogUnits} from '../../../interfaces/catalog/ICheckCatalogUnits';
import {ICreateObjectData} from '../../../interfaces/createData/ICreateObjectData';
import {ICheckCatalogUnit} from '../../../interfaces/catalog/ICheckCatalogUnit';
import {
    CATALOG_CALCULATE_TYPE_DETAILS,
    CATALOG_CALCULATE_TYPE_MODULE,
    CHECK_CATALOG_NOT_STRICT,
    CHECK_CATALOG_STRICT,
    FACADE_CALCULATE_NONE,
    FACADE_CALCULATE_SELF_AMOUNT,
    FACADE_FOR_SECOND,
    FACADE_MATERIAL_TYPE_CORPUS_MATERIAL,
    FACADE_MATERIAL_TYPE_DEFAULT,
    FACADE_MATERIAL_TYPE_SECOND,
    FURNITURE_FT_HANDLE,
    FURNITURE_TYPE_NONE,
    GOOD_TYPE_MODULE,
    GOOD_TYPE_TABLETOP,
    NONE_MATERIAL,
    OPTION_TYPE_JSON,
    PRICE_CELL_CORPUS,
    PRICE_CELL_EXTRA_OFFERS,
    PRICE_CELL_FACADE,
    PRICE_CELL_FURNITURE,
    PRICE_CELL_HANDLE,
    PRICE_CELL_MODULE,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_DEFAULT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP
} from '../../../constants';
import {TCatalogCalculateType} from '../../../types/TCatalogCalculateType';
import {TSelectItem} from '../../../types/TSelectItem';
import {ICreateObjectSelectOptions} from '../../../interfaces/createData/ICreateObjectSelectOptions';
import {IOption} from '../../../interfaces/option/IOption';
import {IOptionGroup} from '../../../interfaces/option/IOptionGroup';
import {IOptionSelect} from '../../../interfaces/option/IOptionSelect';
import {IModulePriceParams} from '../../../interfaces/catalog/IModulePriceParams';
import {IModulePriceData} from '../../../interfaces/catalog/IModulePriceData';
import {IMaterialData} from '../../../interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../../interfaces/materials/IFacadeMaterialData';
import {TSizes} from '../../../types/geometry/TSizes';
import {TCreateUnitSizes} from '../../../interfaces/createData/TCreateUnitSizes';
import {CommonHelper} from '../CommonHelper/CommonHelper';
import {i18n} from '../i18n';
import {TDirectionSideType} from '../../../types/TDirectionSideType';
import {ISaveFacadeData} from '../../../interfaces/saveData/ISaveFacadeData';
import {IFacadePriceParam} from '../../../interfaces/catalog/IFacadePriceParam';
import {ICorpusPriceParams} from '../../../interfaces/catalog/ICorpusPriceParams';
import {TDataForSizeByParent} from '../../../types/TDataForSizeByParent';
import {IFurniturePriceParams} from '../../../interfaces/catalog/IFurniturePriceParams';
import {TFacadeSizes} from '../../../types/TFacadeSizes';
import {TFacadeInitSizes} from '../../../types/TFacadeInitSizes';
import {TFacadeGap} from '../../../types/TFacadeGap';
import {TOptionalFacadeSizes} from '../../../types/TOptionalFacadeSizes';
import {IOfferPriceParam} from '../../../interfaces/catalog/IOfferPriceParam';
import {ICorpusPriceData} from '../../../interfaces/catalog/ICorpusPriceData';
import {ICorpusOffers} from '../../../interfaces/catalog/ICorpusOffers';
import {IFacadePriceData} from '../../../interfaces/catalog/IFacadePriceData';
import {IDetailPriceData} from '../../../interfaces/catalog/IDetailPriceData';
import {IHingePriceParams} from '../../../interfaces/catalog/IHingePriceParams';
import {IOffer} from '../../../interfaces/catalog/IOffer';
import {IAPIProduct} from '../../../interfaces/api/IAPIProduct';
import {IAPIProductItem} from '../../../interfaces/api/IAPIProductItem';
import {ICheckCatalogUnitKitItem} from '../../../interfaces/catalog/ICheckCatalogUnitKitItem';
import {IFurnitureOffer} from '../../../interfaces/catalog/IFurnitureOffer';
import {TKitchenHelperOptions} from '../../../types/TKitchenHelperOptions';
import {IModuleOffers} from '../../../interfaces/catalog/IModuleOffers';
import {IOptionRadioButton} from '../../../interfaces/option/IOptionRadioButton';
import {IProductPrice} from '../../../interfaces/catalog/IproductPrice';
import {ISaveUnitData} from '../../../interfaces/saveData/ISaveUnitData';
import {TCheckCatalogType} from '../../../types/appConfig/TCheckCatalogType';
import {TPriceCell} from '../../../types/price/TPriceCell';
import {CatalogHelper} from '../CatalogHelper/CatalogHelper';
import {TError} from '../../../types/TError';
import {IFacadeData} from '../../../interfaces/materials/IFacadeData';
import {ISaveKUnitDetailData} from '../../../interfaces/saveData/ISaveKUnitDetailData';
import {TFacadeCalculateType} from '../../../types/TFacadeCalculateType';
import {TFacadeModelType} from '../../../types/TFacadeModelType';
import {IHandlePriceParams} from '../../../interfaces/catalog/IHandlePriceParams';
import {ISaveHandleData} from '../../../interfaces/saveData/ISaveHandleData';
import {IAccessoryPriceParam} from '../../../interfaces/catalog/IAccessoryPriceParam';
import {ITechnologMaps} from '../../../interfaces/ITechnologMaps';
import {ITechnologMap} from '../../../interfaces/ITechnologMap';
import {IStockInWay} from '../../../interfaces/catalog/IStockInWay';
import {IStocksInWay} from '../../../interfaces/catalog/IStocksInWay';
import {IDetailsStocksInWay} from '../../../interfaces/catalog/IDetailsStocksInWay';

export class KitchenHelper {
    options: TKitchenHelperOptions;
    appConfig: IAppConfig;
    units: ICreateGroup[];
    unitKits: IUnitKits;
    unitPrices: IUnitKitPrices;
    corpusMaterials: ICorpusMaterials;
    glasses: ICorpusMaterials;
    facadeMaterials: IFacadeMaterials;
    modulesOffers: {[key: string]: IImportOffer | false};

    constructor(
        options: TKitchenHelperOptions,
        appConfig: IAppConfig,
        units: ICreateGroup[],
        unitKits: IUnitKits,
        unitPrices: IUnitKitPrices,
        corpusMaterials: ICorpusMaterials,
        facadeMaterials: IFacadeMaterials,
        glasses: ICorpusMaterials
    ) {
        this.options = options;
        this.appConfig = appConfig;
        this.units = units;
        this.unitKits = unitKits;
        this.unitPrices = unitPrices;
        this.corpusMaterials = corpusMaterials;
        this.facadeMaterials = facadeMaterials;
        this.glasses = glasses;
        this.modulesOffers = {};
    }

    public setAppConfig(appConfig: IAppConfig) {
        this.appConfig = appConfig;
    }

    public setUnits(units: ICreateGroup[]) {
        this.units = units;
    }

    public setUnitKits(unitKits: IUnitKits) {
        this.unitKits = unitKits;
    }

    public setCorpusMaterials(corpusMaterials: ICorpusMaterials) {
        this.corpusMaterials = corpusMaterials;
    }

    public setGlasses(glasses: ICorpusMaterials) {
        this.glasses = glasses;
    }

    public setFacadeMaterials(facadeMaterials: IFacadeMaterials) {
        this.facadeMaterials = facadeMaterials;
    }

    public setUnitPrices(unitPrices: IUnitKitPrices) {
        this.unitPrices = unitPrices;
    }

    public clearModulesOffers() {
        this.modulesOffers = {};
    }

    public calculateCreateObjectSizes(createOptions: any): TCreateUnitSizes {
        let unitSizes: TSizes | undefined;
        let corpusSizes: TSizes | undefined;

        if (createOptions.corpus &&
            createOptions.corpus.width &&
            createOptions.corpus.height &&
            createOptions.corpus.depth) {
            corpusSizes = {
                length: +createOptions.corpus.width,
                height: +createOptions.corpus.height,
                width: +createOptions.corpus.depth
            }
        }
        if (createOptions.sizes &&
            createOptions.sizes.width &&
            createOptions.sizes.height &&
            createOptions.sizes.depth) {
            unitSizes = {
                length: +createOptions.sizes.width,
                height: +createOptions.sizes.height,
                width: +createOptions.sizes.depth
            }
        }


        if (corpusSizes &&
            (isNaN(corpusSizes.length) ||
                isNaN(corpusSizes.width) ||
                isNaN(corpusSizes.height))) {
            corpusSizes = undefined;
        }
        if (unitSizes &&
            (isNaN(unitSizes.length) ||
                isNaN(unitSizes.width) ||
                isNaN(unitSizes.height))) {
            unitSizes = undefined;
        }

        return {
            unit: unitSizes,
            corpus: corpusSizes
        }
    }

    public getCheckUnitCategory(_unit: ICreateObjectData): string | undefined {
        return undefined;
    }

    public createModulesFeed(checkUnits: ICheckCatalogUnits): Promise<IAPIProduct[]> {
        return new Promise<IAPIProduct[]>((resolve) => {
            let key: string;
            let sizeId: string;
            let corpusMaterialId: string;
            let facadeMaterialId: string;
            let kitId: string;
            let apiProducts: IAPIProduct[] = [];
            let importOffer: IImportOffer | undefined;
            let apiProduct: IAPIProduct;
            let checkUnitData: ICheckCatalogUnit;
            let modulePrice: IModulePriceData;
            let corpusMaterial: IMaterialData | undefined;
            let facadeMaterial: IFacadeMaterialData | undefined;
            let kitItem: ICheckCatalogUnitKitItem;

            for (key in checkUnits) {
                checkUnitData = checkUnits[key];
                for (sizeId in checkUnitData.sizes) {
                    for (corpusMaterialId in checkUnitData.sizes[sizeId].corpusMaterials) {
                        for (facadeMaterialId in checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials) {
                            for (kitId in checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials[facadeMaterialId].kits) {
                                kitItem = checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials[facadeMaterialId].kits[kitId];
                                modulePrice = kitItem.price;
                                if (!modulePrice.errors.length) {
                                    importOffer = modulePrice.offer ? modulePrice.offer : modulePrice.module?.offer;
                                    corpusMaterial = this.corpusMaterials[corpusMaterialId];
                                    facadeMaterial = this.facadeMaterials[facadeMaterialId];
                                    apiProduct = {
                                        name: this.getCheckUnitTitle(
                                            checkUnitData.unit,
                                            {
                                                length: checkUnitData.sizes[sizeId].width,
                                                height: checkUnitData.sizes[sizeId].height,
                                                width: checkUnitData.sizes[sizeId].depth
                                            },
                                            this.corpusMaterials[corpusMaterialId],
                                            this.facadeMaterials[facadeMaterialId]
                                        ),
                                        type: GOOD_TYPE_MODULE,
                                        vendorCode: importOffer?.vendorCode || '',
                                        guid: importOffer?.externalGuid,
                                        article: importOffer?.article,
                                        catalogCode: checkUnitData.unit.catalogCode,
                                        items: this.getModulePriceApiProductItems(modulePrice),
                                        height: checkUnitData.sizes[sizeId].height,
                                        width: checkUnitData.sizes[sizeId].width,
                                        depth: checkUnitData.sizes[sizeId].depth,
                                        facadeMaterial: facadeMaterial ? facadeMaterial.title : '',
                                        corpusMaterial: corpusMaterial ? corpusMaterial.title : '',
                                        moduleOffer: importOffer,
                                        image: kitItem.visual,
                                        sketchImage: kitItem.sketch,
                                        category: checkUnitData.group.title,
                                    }
                                    apiProducts.push(apiProduct);
                                }
                            }
                        }
                    }
                }
            }
            resolve(apiProducts);
        });
    }

    public calculateCreateObjectCreateOptions(
        createOptions: any,
        corpusMaterial: IMaterialData,
        facadeMaterial: IFacadeMaterialData
    ): any {
        let resultCreateOptions: any;
        let index: string;
        let facadeData: ISaveFacadeData;

        resultCreateOptions = CommonHelper.deepCopy(createOptions);
        if (resultCreateOptions.corpus) {
            resultCreateOptions.corpus.material = corpusMaterial.id;
            resultCreateOptions.corpusMaterial = corpusMaterial.id;
        }
        if (resultCreateOptions.facades && Object.values(resultCreateOptions.facades).length) {
            resultCreateOptions.facadeMaterial = facadeMaterial.id;
            for (index in resultCreateOptions.facades) {
                facadeData = createOptions.facades[index] as ISaveFacadeData;
                if (facadeData.calculateType !== FACADE_CALCULATE_SELF_AMOUNT &&
                    facadeData.calculateType !== FACADE_CALCULATE_NONE &&
                    facadeData.for !== FACADE_FOR_SECOND) {
                    resultCreateOptions.facades[index].material = facadeMaterial.id
                }
            }
        }

        return resultCreateOptions;
    }

    public calculateCreateObjectPriceParams(
        objectData: ICreateObjectData,
        createOptions: any,
        technologMaps: ITechnologMaps,
        defaultCorpusMaterial: IMaterialData,
        defaultFacadeMaterial: string,
        defaultFacadeMaterial2: string | undefined,
        corpusFacadeMaterialId: string | undefined,
        initSideType?: TDirectionSideType,
        defaultTabletops?: IAccessoryPriceParam[]
    ): IModulePriceParams | undefined {
        let width: number;
        let height: number;
        let depth: number;
        let sideType: TDirectionSideType;
        let facadeMaterial: string | undefined;
        let selfFacadeMaterial: string | undefined;
        let facadeMaterial2: string | undefined;
        let corpusMaterial: string | undefined;
        let panelMaterial: string | undefined;
        let frameMaterial: string | undefined;
        let params: IModulePriceParams;
        let facadeData: ISaveFacadeData;
        let facadesParams: IFacadePriceParam[];
        let corpusParams: ICorpusPriceParams | undefined;
        let corpusSizes: TSizes;

        width = 0;
        height = 0;
        depth = 0;
        if (createOptions.corpus) {
            if (!isNaN(+createOptions.corpus.width)) {
                width = +createOptions.corpus.width;
            }
            if (!isNaN(+createOptions.corpus.depth)) {
                depth = +createOptions.corpus.depth;
            }
            if (!isNaN(+createOptions.corpus.height)) {
                height = +createOptions.corpus.height;
            }
            frameMaterial = createOptions.corpus.frameMaterial || undefined;
            panelMaterial = createOptions.corpus.panelMaterial || undefined;
        }

        if (createOptions.sizes) {
            if (!isNaN(+createOptions.sizes.width)) {
                width = +createOptions.sizes.width;
            }
            if (!isNaN(+createOptions.sizes.depth)) {
                depth = +createOptions.sizes.depth;
            }
            if (!isNaN(+createOptions.sizes.height)) {
                height = +createOptions.sizes.height;
            }
        }
        if (createOptions.isSameWidthDepth && width && !isNaN(+width)) {
            depth = width;
        }
        if ((width <= 0 || height <= 0 || depth <= 0) && !objectData.notPrice) {
            return undefined;
        }
        sideType = initSideType || createOptions.sideType || SIDE_TYPE_DEFAULT;
        sideType = (!createOptions.availableSideTypes || createOptions.availableSideTypes.includes(sideType)) ?
            sideType : (createOptions.availableSideTypes && createOptions.availableSideTypes[0] ?
                createOptions.availableSideTypes[0] : SIDE_TYPE_DEFAULT);
        if (createOptions.corpus && createOptions.corpus.material) {
            corpusMaterial = createOptions.corpus.material;
        } else if (createOptions.corpusMaterial && createOptions.corpusMaterial) {
            corpusMaterial = createOptions.corpusMaterial;
        } else {
            corpusMaterial = defaultCorpusMaterial.id;
        }
        if (createOptions.facades) {
            if (createOptions.facades.filter(
                (item: ISaveFacadeData) => {
                    return item.calculateType === FACADE_CALCULATE_SELF_AMOUNT
                }
            ).length === createOptions.facades.length) {
                facadeMaterial = NONE_MATERIAL;
                if (createOptions.facadeMaterial) {
                    selfFacadeMaterial = createOptions.facadeMaterial;
                }
            }
            for (facadeData of createOptions.facades) {
                if (facadeData.calculateType === FACADE_CALCULATE_SELF_AMOUNT) {
                    continue;
                }
                if (facadeData.for && facadeData.for === FACADE_FOR_SECOND && facadeData.calculateType !== FACADE_CALCULATE_NONE) {
                    facadeMaterial2 = defaultFacadeMaterial2;
                } else if (facadeData.calculateType !== FACADE_CALCULATE_NONE) {
                    facadeMaterial = defaultFacadeMaterial;
                }
                if (facadeMaterial && facadeMaterial2) {
                    break;
                }
            }
        } else if (createOptions.facadeMaterial !== undefined) {
            facadeMaterial = createOptions.facadeMaterial;
        }

        corpusParams = KitchenHelper.calculateCorpusParams(createOptions, corpusMaterial, {});
        corpusSizes = corpusParams ?
            {length: corpusParams.width, height: corpusParams.height, width: corpusParams.depth} :
            {length: width, height: height, width: depth}
        facadesParams = KitchenHelper.calculateFacadesParams(
            createOptions,
            sideType,
            facadeMaterial,
            selfFacadeMaterial,
            {},
            corpusSizes,
            corpusFacadeMaterialId,
            facadeMaterial2
        );
        if (!corpusMaterial) {
            corpusMaterial = NONE_MATERIAL;
        }
        if (!facadeMaterial) {
            facadeMaterial = NONE_MATERIAL;
        }

        params = {
            catalogCode: objectData.catalogCode,
            level: objectData.level,
            width: width,
            height: height,
            depth: depth,
            sideType: sideType,
            corpusMaterial: corpusMaterial,
            facadeMaterial: facadeMaterial,
            panelMaterial: panelMaterial,
            frameMaterial: frameMaterial,
            notPrice: objectData.notPrice,
            corpus: corpusParams,
            facades: facadesParams,
            calculateType: this.getCreateObjectCalculateType(objectData, technologMaps),
            extraOffers: this.getCreateObjectExtraOffersPriceParams(objectData),
            checkCatalogType: objectData.checkCatalogType,
            unitId: 0,
            cell: PRICE_CELL_MODULE,
            canStretch: objectData.canStretch,
            isStretch: objectData.isStretch,
            tabletops: defaultTabletops,
        };
        if (facadeMaterial2) {
            params.facadeMaterial2 = facadeMaterial2;
        }

        return params;
    }

    public calculatePriceSelfFacades(facadesParams: IFacadePriceParam[]): IFacadePriceData[] | undefined {
        let facadeParams: IFacadePriceParam;
        let facadePrice: IFacadePriceData | undefined;
        let facadeOffer: IImportOffer | undefined;
        let facadePrices: IFacadePriceData[];
        let cellIndex: number;
        let errorFacadePrice: {price: IDetailPriceData, error: TError};
        let modulePriceData: IModulePriceData;

        modulePriceData = {
            id: '',
            price: 0,
            oldPrice: 0,
            errors: [],
            cell: PRICE_CELL_MODULE,
            unitId: 0,
            sizes: {
                width: 0,
                height: 0,
                depth: 0,
            },
        };
        facadePrices = [];
        cellIndex = 0;
        for (facadeParams of facadesParams) {
            if (facadeParams.calculateType !== FACADE_CALCULATE_SELF_AMOUNT) {
                continue;
            }
            if (!this.unitKits || !this.unitKits['facade']) {
                errorFacadePrice = this.getErrorFacadePrice(facadeParams, cellIndex);
                facadePrices.push(errorFacadePrice.price);
                cellIndex++;
                continue;
            }
            facadeOffer = KitchenHelper.calculateFacadeOffer(
                facadeParams,
                {width: facadeParams.width, height: facadeParams.height},
                facadeParams.facadeMaterial,
                this.unitKits['facade']
            );
            if (!facadeOffer) {
                errorFacadePrice = this.getErrorFacadePrice(facadeParams, cellIndex);
                facadePrices.push(errorFacadePrice.price);
                cellIndex++;
                continue;
            }
            facadePrice = this.getFacadePriceDataFromOffer(
                facadeOffer,
                facadeParams,
                modulePriceData,
                cellIndex
            );
            facadePrices.push(facadePrice);
            cellIndex++;
        }

        return facadePrices.length ? facadePrices : undefined;
    }

    public calculatePriceByDetails(params: IModulePriceParams): IModulePriceData | undefined {
        let corpusPriceData: ICorpusPriceData | undefined;
        let modulePriceData: IModulePriceData;
        let externalId: 'externalGuid' | 'vendorCode';
        let modulePrice: IProductPrice | undefined;

        if (!this.unitKits) {
            return undefined;
        }
        if (!params.corpus && params.corpusMaterial !== NONE_MATERIAL) {
            return undefined;
        }
        if (!params.facades) {
            return undefined;
        }
        if (params.corpus && !this.unitKits[params.corpus.catalogCode]) {
            return undefined;
        }

        externalId = this.getOfferExternalId();
        modulePriceData = {
            id: '',
            price: 0,
            oldPrice: 0,
            errors: [],
            cell: params.cell,
            unitId: params.unitId,
            sizes: {
                width: params.width,
                height: params.height,
                depth: params.depth,
            },
        }
        if (params.corpus) {
            corpusPriceData = this.calculateCorpusPrice(params.corpus, modulePriceData);
            if (!corpusPriceData) {
                modulePriceData.errors.push({id: 'calculateKit', message: 'Не нашли товар корпуса'});
            } else {
                modulePriceData.corpus = corpusPriceData;
            }
        }
        this.calculateFacadesPriceDataByModule(
            params.facades.filter(item => item.calculateType !== FACADE_CALCULATE_SELF_AMOUNT),
            modulePriceData
        );
        this.calculateModuleExtraOffersPriceData(params.unitId, modulePriceData, params.extraOffers);
        this.calculateModuleTabletopsPriceData(params.unitId, modulePriceData, params.tabletops);
        if (!modulePriceData.errors.length &&
            this.appConfig.catalog.calculateType === CATALOG_CALCULATE_TYPE_DETAILS &&
            this.getCheckCatalogType(params) === CHECK_CATALOG_STRICT) {
            modulePriceData.offer = this.getModuleOfferByDetails(modulePriceData, params.catalogCode);
            if (modulePriceData.offer) {
                modulePrice = this.getOfferProductPrice(modulePriceData.offer[externalId]);
                if (modulePrice) {
                    if (modulePrice.price !== undefined &&
                        !isNaN(+modulePrice.price) &&
                        modulePrice.price < modulePriceData.price) {
                        modulePriceData.price = modulePrice.price;
                    }
                    if (modulePrice.oldPrice !== undefined &&
                        !isNaN(+modulePrice.oldPrice) &&
                        modulePriceData.oldPrice !== undefined &&
                        modulePriceData.oldPrice < modulePrice.oldPrice) {
                        modulePriceData.oldPrice = modulePrice.oldPrice;
                    }
                    modulePriceData.active = modulePrice.active;
                    modulePriceData.stock = modulePrice.stock;
                    modulePriceData.onlyOrder = modulePrice.onlyOrder;
                    modulePriceData.stocksInWay = modulePrice.stocksInWay;
                }
            } else if (this.getCheckCatalogType(params) === CHECK_CATALOG_STRICT){
                modulePriceData.errors.push({id: 'calculateKit', message: 'Не нашли товар сборного модуля'});
            }
        }
        this.calculateFacadesPriceDataByModule(
            params.facades.filter(item => item.calculateType === FACADE_CALCULATE_SELF_AMOUNT),
            modulePriceData
        );
        this.setDetailsPriceDataToModulePrice(modulePriceData);
        if (modulePriceData) {
            this.calculateModulePriceDataStocksInWay(modulePriceData);
        }

        return modulePriceData;
    }

    public setDetailsPriceDataToModulePrice(modulePriceData: IModulePriceData) {
        if (modulePriceData.corpus && modulePriceData.corpus.active === false) {
            modulePriceData.active = false;
        }
        if (modulePriceData.module && modulePriceData.module.active === false) {
            modulePriceData.active = false;
        }
        if (modulePriceData.corpus &&
            modulePriceData.corpus.furniture &&
            modulePriceData.corpus.furniture.length &&
            modulePriceData.corpus.furniture.filter(item => item.active === false).length) {
            modulePriceData.active = false;
        }
        if (modulePriceData.facades &&
            modulePriceData.facades.length &&
            modulePriceData.facades.filter(item => item.active === false).length) {
            modulePriceData.active = false;
        }
        if (modulePriceData.extraOffers &&
            modulePriceData.extraOffers.length &&
            modulePriceData.extraOffers.filter(item => item.active === false).length) {
            modulePriceData.active = false;
        }
        if (modulePriceData.tabletops &&
            modulePriceData.tabletops.length &&
            modulePriceData.tabletops.filter(item => item.active === false).length) {
            modulePriceData.active = false;
        }
    }

    public getCheckCatalogType(unitData: ICreateObjectData | ISaveUnitData | IModulePriceParams, isStretch?: boolean): TCheckCatalogType | undefined {
        if (isStretch) {
            return CHECK_CATALOG_NOT_STRICT;
        }
        return unitData.checkCatalogType || this.appConfig.catalog.checkCatalogType;
    }

    public calculateHingesPriceData(hingesParams: IHingePriceParams[], modulePriceData: IModulePriceData): IDetailPriceData[] {
        let hingesParam: IHingePriceParams;
        let detailPrice: IDetailPriceData;
        let hinges: { [key: string]: IDetailPriceData } = {};
        let id: string;
        let cellIndex: number;

        cellIndex = 0;
        for (hingesParam of hingesParams) {
            detailPrice = this.calculateFurniturePriceData(hingesParam, modulePriceData, cellIndex);
            if (detailPrice.offer) {
                id = detailPrice.offer[this.getOfferExternalId()];
                if (!hinges[id]) {
                    hinges[id] = detailPrice;
                } else {
                    hinges[id].count += 1;
                }
            }
        }

        return Object.values(hinges);
    }

    public calculateFurniturePriceData(
        furnitureParams: IFurniturePriceParams,
        modulePriceData: IModulePriceData,
        cellIndex: number
    ): IDetailPriceData {
        let furnitureOffer: IImportOffer | undefined;
        let furniturePriceData: IDetailPriceData;
        let offerProductPrice: IProductPrice | undefined;

        if (this.unitKits && this.unitKits[furnitureParams.catalogCode]) {
            furnitureOffer = KitchenHelper.calculateFurnitureOffer(
                furnitureParams,
                this.unitKits[furnitureParams.catalogCode]
            );
        }

        if (furnitureOffer) {
            furniturePriceData = this.getDetailPriceDataFromOffer(
                furnitureOffer,
                furnitureParams.unitId,
                furnitureParams.cell,
                cellIndex
            );
            if (furniturePriceData.offer) {
                offerProductPrice = this.getOfferProductPrice(furniturePriceData.offer[this.getOfferExternalId()]);
                if (offerProductPrice) {
                    modulePriceData.price += offerProductPrice.price * furniturePriceData.count;
                    if (modulePriceData.oldPrice && offerProductPrice.oldPrice) {
                        modulePriceData.oldPrice += (offerProductPrice.oldPrice as number) * furniturePriceData.count;
                    }
                }
            }
        } else {
            furniturePriceData = {
                id: '',
                price: 0,
                count: 1,
                errors: [{id: 'calculateKit', message: 'Не нашли товар ручки'}],
                unitId: furnitureParams.unitId,
                cell: furnitureParams.cell,
                cellIndex: cellIndex,
                sizes: {
                    width: furnitureParams.width,
                    height: furnitureParams.height,
                    depth: furnitureParams.depth
                },
            };
            modulePriceData.errors.push({id: 'calculateKit', message: 'Не нашли товар ручки'});
        }


        return furniturePriceData;
    }

    public getFacadePriceDataFromOffer(
        offer: IImportOffer,
        facadeParams: IFacadePriceParam,
        modulePriceData: IModulePriceData,
        cellIndex: number
    ): IFacadePriceData {
        let detailPrice: IDetailPriceData;
        let facadePrice: IFacadePriceData;

        detailPrice = this.getDetailPriceDataFromOffer(offer, facadeParams.unitId, facadeParams.cell, cellIndex);

        facadePrice = {
            ...detailPrice,
            facadeMaterialType: facadeParams.facadeMaterialType
        };
        if (facadeParams.handle) {
            facadePrice.handle = this.calculateFurniturePriceData(facadeParams.handle, modulePriceData, cellIndex);
        }
        if (facadeParams.hinges) {
            facadePrice.hinges = this.calculateHingesPriceData(facadeParams.hinges, modulePriceData);
        }

        return facadePrice;
    }

    public getDetailPriceDataFromOffer(
        offer: IImportOffer,
        unitId: number,
        cell: TPriceCell,
        cellIndex: number
    ): IDetailPriceData {
        let detailPrice: IDetailPriceData;
        let offerProductPrice: IProductPrice | undefined;

        offerProductPrice = this.getOfferProductPrice(offer[this.getOfferExternalId()]);
        if (!offerProductPrice) {
            detailPrice = {
                id: offer[this.getOfferExternalId()],
                price: 0,
                errors: [],
                count: 1,
                note: i18n.t('Идет расчет...') || '',
                offer: offer,
                cell: cell,
                unitId: unitId,
                cellIndex: cellIndex,
                sizes: {width: offer.width, height: offer.height, depth: offer.depth},

            };
        } else {
            detailPrice = {
                id: offer[this.getOfferExternalId()],
                price: offerProductPrice.price,
                oldPrice: offerProductPrice.oldPrice,
                active: offerProductPrice.active,
                onlyOrder: offerProductPrice.onlyOrder,
                stocksInWay: offerProductPrice.stocksInWay,
                stock: offerProductPrice.stock,
                count: 1,
                errors: [],
                offer: offer,
                cell: cell,
                unitId: unitId,
                cellIndex: cellIndex,
                sizes: {width: offer.width, height: offer.height, depth: offer.depth},
            };
        }

        return detailPrice;
    }

    public calculateCorpusPrice(
        corpusParams: ICorpusPriceParams,
        modulePriceData: IModulePriceData
    ): ICorpusPriceData | undefined {
        let corpusOffers: ICorpusOffers | undefined;
        let furnitureOffer: IFurnitureOffer;
        let corpusPriceData: ICorpusPriceData;
        let corpusFurniture: ICorpusPriceData[] | undefined;
        let furniturePriceData: ICorpusPriceData | undefined;
        let cellIndex: number;
        let offerProductPrice: IProductPrice | undefined;

        if (!this.unitKits) {
            return undefined;
        }
        corpusOffers = KitchenHelper.calculateCorpusOffers(
            corpusParams,
            this.unitKits
        );
        if (!corpusOffers) {
            return undefined;
        }
        offerProductPrice = this.getOfferProductPrice(corpusOffers.offer[this.getOfferExternalId()]);
        if (!offerProductPrice) {
            corpusPriceData = {
                id: corpusOffers.offer[this.getOfferExternalId()],
                price: 0,
                count: 1,
                errors: [],
                note: i18n.t('Идет расчет...') || '',
                offer: corpusOffers.offer,
                cell: corpusParams.cell,
                unitId: corpusParams.unitId,
                cellIndex: 0,
                sizes: {width: corpusParams.width, height: corpusParams.height, depth: corpusParams.depth},
            };
        } else {
            modulePriceData.price += offerProductPrice.price;
            if (modulePriceData.oldPrice && offerProductPrice.oldPrice) {
                modulePriceData.oldPrice += offerProductPrice.oldPrice as number;
            }
            corpusPriceData = {
                id: corpusOffers.offer[this.getOfferExternalId()],
                price: offerProductPrice.price,
                oldPrice: offerProductPrice.oldPrice,
                active: offerProductPrice.active,
                onlyOrder: offerProductPrice.onlyOrder,
                stocksInWay: offerProductPrice.stocksInWay,
                stock: offerProductPrice.stock,
                count: 1,
                errors: [],
                offer: corpusOffers.offer,
                cell: corpusParams.cell,
                unitId: corpusParams.unitId,
                cellIndex: 0,
                sizes: {width: corpusParams.width, height: corpusParams.height, depth: corpusParams.depth},
            };
        }
        corpusFurniture = [];
        cellIndex = 0;
        // TODO добавить проверку ошибки расчета фурнитуры корпуса
        for (furnitureOffer of corpusOffers.furniture) {
            offerProductPrice = this.getOfferProductPrice(furnitureOffer.offer[this.getOfferExternalId()]);
            if (!offerProductPrice) {
                furniturePriceData = {
                    id: furnitureOffer.offer[this.getOfferExternalId()],
                    price: 0,
                    count: corpusParams.furniture ? corpusParams.furniture[+furnitureOffer.index].count : 1,
                    errors: [],
                    note: i18n.t('Идет расчет...') || '',
                    offer: furnitureOffer.offer,
                    cell: furnitureOffer.cell,
                    unitId: furnitureOffer.unitId,
                    cellIndex: cellIndex,
                    sizes: {
                        width: furnitureOffer.offer.width,
                        height: furnitureOffer.offer.height,
                        depth: furnitureOffer.offer.depth
                    },
                };
            } else {
                furniturePriceData = {
                    id: furnitureOffer.offer[this.getOfferExternalId()],
                    price: offerProductPrice.price,
                    oldPrice: offerProductPrice.oldPrice,
                    active: offerProductPrice.active,
                    onlyOrder: offerProductPrice.onlyOrder,
                    stocksInWay: offerProductPrice.stocksInWay,
                    stock: offerProductPrice.stock,
                    count: corpusParams.furniture ? corpusParams.furniture[+furnitureOffer.index].count : 1,
                    errors: [],
                    offer: furnitureOffer.offer,
                    unitId: furnitureOffer.unitId,
                    cell: furnitureOffer.cell,
                    cellIndex: cellIndex,
                    sizes: {
                        width: furnitureOffer.offer.width,
                        height: furnitureOffer.offer.height,
                        depth: furnitureOffer.offer.depth
                    },
                };
            }
            modulePriceData.price += furniturePriceData.price * furniturePriceData.count;
            if (modulePriceData.oldPrice && furniturePriceData.oldPrice) {
                modulePriceData.oldPrice += furniturePriceData.oldPrice as number * furniturePriceData.count;
            }
            corpusFurniture.push(furniturePriceData);
            cellIndex++;
        }
        if (corpusPriceData && corpusFurniture && corpusFurniture.length > 0) {
            corpusPriceData.furniture = corpusFurniture;
            if (corpusFurniture.filter((value) => {
                const itemPrice: IProductPrice | undefined = value.offer ? this.getOfferProductPrice(value.offer[this.getOfferExternalId()]) : undefined;

                return !(value.offer && itemPrice &&
                    itemPrice.active !== undefined &&
                    !itemPrice.active);
            }).length !== corpusFurniture.length) {
                return undefined;
            }
        }

        return corpusPriceData;
    }

    public getOfferExternalId(): 'externalGuid' | 'vendorCode' {
        return KitchenHelper.getOfferExternalId(this.appConfig);
    }

    public getCreateObjectCalculateType(
        createObject: ICreateObjectData,
        technologMaps: ITechnologMaps
    ): TCatalogCalculateType {
        return KitchenHelper.getCreateObjectCalculateType(
            createObject,
            this.appConfig,
            technologMaps
        );
    }

    public getCreateObjectExtraOffersPriceParams(
        createObject: ICreateObjectData
    ): IOfferPriceParam[] | undefined {
        let offerParams: IOfferPriceParam[] = [];
        let option: IOption | IOptionGroup;
        let optionValue: any;
        let index: string;
        let offer: IImportOffer;
        const externalIdName: 'externalGuid' | 'vendorCode' = this.getOfferExternalId();

        for (option of createObject.options) {
            if (option.id === "extraOffers" && "value" in option && option.value !== undefined) {
                optionValue = option.value;
                for (index in optionValue) {
                    if (optionValue[index].catalogCode && this.unitKits[optionValue[index].catalogCode]) {
                        for (offer of this.unitKits[optionValue[index].catalogCode]) {
                            if (this.checkExtraOfferFilter(offer, optionValue[index].filter)) {
                                offerParams.push({
                                    id: offer[externalIdName],
                                    count: !isNaN(+optionValue[index].count) ? +optionValue[index].count : 1
                                })
                                break;
                            }
                        }
                    }
                }
                break;
            }
        }

        return offerParams.length ? offerParams : undefined;
    }

    public checkExtraOfferFilter(offer: IImportOffer, filter?: any): boolean {
        if (!filter) {
            return true;
        }
        return !(filter.sizes && ((filter.sizes.height && offer.height !== filter.sizes.height) ||
            (filter.sizes.depth && offer.depth !== filter.sizes.depth)
            (filter.sizes.width && offer.width !== filter.sizes.width)));

    }

    public getModulePriceApiProductItems(modulePrice: IModulePriceData): IAPIProductItem[] {
        let items: { [key: string]: IAPIProductItem } = {};
        let detailPrice: IDetailPriceData;
        let facadePrice: IFacadePriceData;

        if (modulePrice.module && modulePrice.module.offer) {
            this.setUniqueProductItem(modulePrice.module, items);
        }
        if (modulePrice.corpus && modulePrice.corpus.offer) {
            this.setUniqueProductItem(modulePrice.corpus, items);
        }
        if (modulePrice.corpus && modulePrice.corpus.furniture) {
            for (detailPrice of modulePrice.corpus.furniture) {
                this.setUniqueProductItem(detailPrice, items);
            }
        }
        if (modulePrice.facades) {
            for (facadePrice of modulePrice.facades) {
                this.setUniqueProductItem(facadePrice, items);
                if (facadePrice.handle) {
                    this.setUniqueProductItem(facadePrice.handle, items);
                }
                if (facadePrice.hinges) {
                    for (detailPrice of facadePrice.hinges) {
                        this.setUniqueProductItem(detailPrice, items);
                    }
                }
            }
        }
        if (modulePrice.extraOffers) {
            for (detailPrice of modulePrice.extraOffers) {
                this.setUniqueProductItem(detailPrice, items);
            }
        }
        if (modulePrice.tabletops) {
            for (detailPrice of modulePrice.tabletops) {
                this.setUniqueProductItem(detailPrice, items);
            }
        }

        return Object.values(items);
    }

    public getOfferById(offerId: string): IImportOffer | undefined {
        let group: string;
        let offer;
        if (this.unitKits) {
            for (group in this.unitKits) {
                for (offer of this.unitKits[group]) {
                    if (offer[this.getOfferExternalId()] === offerId) {
                        return offer;
                    }
                }
            }
        }

        return undefined;
    }

    public getModuleOfferIds(modulePrice: IModulePriceData): string[] {
        let offerIds: string[] = [];
        let moduleOffers: IOffer[];
        let offer: IOffer;

        moduleOffers = this.getModuleOffers(modulePrice);
        for (offer of moduleOffers) {
            offerIds.push(offer.importOffer[this.getOfferExternalId()]);
        }

        return offerIds;
    }

    public getModuleOfferByDetails(modulePrice: IModulePriceData, catalogCode: string): IImportOffer | undefined {
        let offer: IImportOffer;
        let offers: IImportOffer[];
        let moduleOffers: IModuleOffers;
        let moduleOffersHash: string;
        const externalIdName: 'externalGuid' | 'vendorCode' = this.getOfferExternalId();
        const apiExternalIdName: 'guid' | 'vendorCode' = externalIdName === 'externalGuid' ? 'guid' : 'vendorCode';

        moduleOffers = this.getModuleOfferItems(modulePrice);
        moduleOffersHash = JSON.stringify(moduleOffers);
        if (this.modulesOffers[moduleOffersHash] !== undefined) {
            return this.modulesOffers[moduleOffersHash] !== false ?
                this.modulesOffers[moduleOffersHash] as IImportOffer : undefined;
        }
        if (!this.unitKits[catalogCode]) {
            return undefined;
        }

        offers = this.unitKits[catalogCode];
        for (offer of offers) {
            if (offer.type !== GOOD_TYPE_MODULE) {
                continue;
            }
            if (this.compareOffers(
                offer,
                moduleOffers,
                moduleOffersHash,
                externalIdName,
                apiExternalIdName
            )) {
                this.modulesOffers[moduleOffersHash] = offer;
                return offer;
            }
        }
        this.modulesOffers[moduleOffersHash] = false;

        return undefined;
    }

    public getModuleOfferItems(modulePrice: IModulePriceData): IModuleOffers {
        let moduleOffers: IOffer[];
        let moduleOfferItems: IModuleOffers = {};
        let offer: IOffer;
        let offerId: string;

        moduleOffers = this.getModuleOffers(modulePrice, true);
        for (offer of moduleOffers) {
            offerId = offer.importOffer[this.getOfferExternalId()];
            if (!moduleOfferItems[offerId]) {
                moduleOfferItems[offerId] = +offer.count
            } else {
                moduleOfferItems[offerId] += +offer.count;
            }
        }

        return CommonHelper.sortObject(moduleOfferItems);
    }

    public compareOffers(
        offer: IImportOffer,
        moduleOffers: IModuleOffers,
        moduleOffersHash: string,
        externalIdName: 'externalGuid' | 'vendorCode',
        apiExternalIdName: 'guid' | 'vendorCode'
    ): boolean {
        if (!offer || !moduleOffers || !Object.keys(moduleOffers).length) {
            return false;
        }
        if (offer.itemsHash !== undefined) {
            return offer.itemsHash === moduleOffersHash;
        }
        offer.itemsHash = CommonHelper.generateOfferItemsHash(offer, externalIdName, apiExternalIdName);

        return offer.itemsHash === moduleOffersHash;
    }

    public getModuleOffers(modulePrice: IModulePriceData, excludeExtraOffers?: boolean): IOffer[] {
        let offers: IOffer[] = [];
        let itemPrice: ICorpusPriceData;
        let facadePrice: IFacadePriceData;
        let detailPrice: IDetailPriceData;

        if (modulePrice.module && modulePrice.module.offer) {
            offers.push({
                importOffer: modulePrice.module.offer,
                price: modulePrice.module.price,
                oldPrice: modulePrice.module.oldPrice,
                stock: modulePrice.module.stock,
                active: modulePrice.module.active,
                count: 1,
                part: modulePrice.module.part,
            });
        }
        if (modulePrice.corpus && modulePrice.corpus.offer && modulePrice.corpus.count > 0) {
            offers.push({
                importOffer: modulePrice.corpus.offer,
                price: modulePrice.corpus.price,
                oldPrice: modulePrice.corpus.oldPrice,
                stock: modulePrice.corpus.stock,
                active: modulePrice.corpus.active,
                count: modulePrice.corpus.count,
                part: modulePrice.corpus.part,
            });
        }
        if (modulePrice.corpus && modulePrice.corpus.furniture) {
            for (itemPrice of modulePrice.corpus.furniture) {
                if (itemPrice.offer && itemPrice.count > 0) {
                    offers.push({
                        importOffer: itemPrice.offer,
                        price: itemPrice.price,
                        oldPrice: itemPrice.oldPrice,
                        stock: itemPrice.stock,
                        active: itemPrice.active,
                        count: itemPrice.count,
                        part: itemPrice.part,
                    });
                }
            }
        }
        if (modulePrice.facades) {
            for (facadePrice of modulePrice.facades) {
                if (facadePrice.offer && facadePrice.count > 0) {
                    offers.push({
                        importOffer: facadePrice.offer,
                        price: facadePrice.price,
                        oldPrice: facadePrice.oldPrice,
                        stock: facadePrice.stock,
                        active: facadePrice.active,
                        count: facadePrice.count,
                        part: facadePrice.part,
                    });
                }
                if (facadePrice.handle && facadePrice.handle.offer && facadePrice.handle.count > 0) {
                    offers.push({
                        importOffer: facadePrice.handle.offer,
                        price: facadePrice.handle.price,
                        oldPrice: facadePrice.handle.oldPrice,
                        stock: facadePrice.handle.stock,
                        active: facadePrice.handle.active,
                        count: facadePrice.handle.count,
                        part: facadePrice.handle.part,
                    });
                }
                if (facadePrice.hinges) {
                    for (detailPrice of facadePrice.hinges) {
                        if (detailPrice.offer && detailPrice.count > 0) {
                            offers.push({
                                importOffer: detailPrice.offer,
                                price: detailPrice.price,
                                oldPrice: detailPrice.oldPrice,
                                stock: detailPrice.stock,
                                active: detailPrice.active,
                                count: detailPrice.count,
                                part: detailPrice.part,
                            });
                        }
                    }
                }
            }
        }
        if (!excludeExtraOffers && modulePrice.extraOffers) {
            for (itemPrice of modulePrice.extraOffers) {
                if (itemPrice.offer && itemPrice.count > 0) {
                    offers.push({
                        importOffer: itemPrice.offer,
                        price: itemPrice.price,
                        oldPrice: itemPrice.oldPrice,
                        stock: itemPrice.stock,
                        active: itemPrice.active,
                        count: itemPrice.count,
                        part: itemPrice.part,
                    });
                }
            }
        }
        if (modulePrice.tabletops) {
            for (itemPrice of modulePrice.tabletops) {
                if (itemPrice.offer && itemPrice.count > 0) {
                    offers.push({
                        importOffer: itemPrice.offer,
                        price: itemPrice.price,
                        oldPrice: itemPrice.oldPrice,
                        stock: itemPrice.stock,
                        active: itemPrice.active,
                        count: itemPrice.count,
                        part: itemPrice.part,
                    });
                }
            }
        }

        return offers;
    }

    public calculatePriceByModule(params: IModulePriceParams): IModulePriceData | undefined {
        let modulePriceData: IModulePriceData | undefined;
        let offer: IImportOffer | undefined;
        let externalId: 'externalGuid' | 'vendorCode';
        let offerProductPrice: IProductPrice | undefined;

        if (!this.unitKits ||
            (!params.notPrice && !this.unitKits[params.catalogCode])) {
            return undefined;
        }
        externalId = this.getOfferExternalId();
        if (!params.notPrice) {
            offer = KitchenHelper.calculateModuleOffer(params, this.unitKits[params.catalogCode]);
            if (offer) {
                offerProductPrice = this.getOfferProductPrice(offer[externalId]);
                if (!offerProductPrice) {
                    modulePriceData = {
                        id: offer[externalId],
                        price: 0,
                        errors: [],
                        note: i18n.t('Идет расчет...'),
                        cell: params.cell,
                        unitId: params.unitId,
                        module: {
                            price: 0,
                            errors: [],
                            count: 1,
                            offer: offer,
                            id: offer[externalId],
                            cell: params.cell,
                            unitId: params.unitId,
                            cellIndex: 0,
                            sizes: {
                                width: params.width,
                                height: params.height,
                                depth: params.depth,
                            },
                        },
                        sizes: {
                            width: params.width,
                            height: params.height,
                            depth: params.depth,
                        },
                    };
                } else {
                    modulePriceData = {
                        id: offer[externalId],
                        price: offerProductPrice.price,
                        oldPrice: offerProductPrice.oldPrice,
                        active: offerProductPrice.active,
                        onlyOrder: offerProductPrice.onlyOrder,
                        stocksInWay: offerProductPrice.stocksInWay,
                        stock: offerProductPrice.stock,
                        errors: [],
                        cell: params.cell,
                        unitId: params.unitId,
                        module: {
                            id: offer[externalId],
                            price: offerProductPrice.price,
                            oldPrice: offerProductPrice.oldPrice,
                            active: offerProductPrice.active,
                            onlyOrder: offerProductPrice.onlyOrder,
                            stocksInWay: offerProductPrice.stocksInWay,
                            stock: offerProductPrice.stock,
                            errors: [],
                            offer: offer,
                            count: 1,
                            cell: params.cell,
                            unitId: params.unitId,
                            cellIndex: 0,
                            sizes: {
                                width: params.width,
                                height: params.height,
                                depth: params.depth,
                            },
                        },
                        sizes: {
                            width: params.width,
                            height: params.height,
                            depth: params.depth,
                        },
                    };
                }
                if (params.facades && modulePriceData) {
                    this.calculateFacadesPriceDataByModule(
                        params.facades.filter(item => item.calculateType === FACADE_CALCULATE_SELF_AMOUNT),
                        modulePriceData
                    );
                }
            }
        }
        modulePriceData = this.calculateModuleExtraOffersPriceData(params.unitId, modulePriceData, params.extraOffers);
        if (modulePriceData) {
            this.calculateModulePriceDataStocksInWay(modulePriceData);
        }

        return modulePriceData;
    }

    public getOfferProductPrice(offerExternalId: string): IProductPrice | undefined {
        let productPrice: IProductPrice | undefined;

        productPrice = this.unitPrices[offerExternalId];
        if (productPrice) {
            if (!this.checkIsActiveOffers()) {
                productPrice = {...productPrice, active: true};
            }
        }

        return productPrice;
    }

    public checkIsActiveOffers(): boolean {
        return true;
    }

    public async checkCatalogByPriceId(
        priceId: string,
        appConfig: IAppConfig,
        corpusId: string,
        units: ICreateGroup[],
        technologMaps: ITechnologMaps
    ): Promise<ICheckCatalogUnits> {
        let resultData: ICheckCatalogUnits;
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;
        let checkUnit: ICheckCatalogUnit;

        const unitPrices: IUnitKitPrices = await CatalogHelper.getUnitKitPrices(
            corpusId,
            appConfig,
            priceId,
            []
        );
        this.setUnitPrices(unitPrices);
        this.clearModulesOffers();
        resultData = {};
        for (group of units) {
            for (createUnit of group.items) {
                checkUnit = {
                    sizes: {},
                    unit: createUnit,
                    group: {
                       id: group.id,
                       title: group.title
                    }
                }
                this.calculateCheckUnit(
                    checkUnit,
                    this.getCreateObjectCalculateType(createUnit, technologMaps),
                    technologMaps
                );
                resultData[createUnit.uid] = checkUnit;
            }
        }

        return resultData;
    }

    public async exportModulesFeed(catalogData: ICheckCatalogUnits): Promise<IAPIProduct[] | undefined> {
        if (!catalogData || !Object.keys(catalogData).length) {
            return undefined;
        }
        let key: string;
        let sizeId: string;
        let corpusMaterialId: string;
        let facadeMaterialId: string;
        let kitId: string;
        let apiProducts: IAPIProduct[] = [];
        let importOffer: IImportOffer | undefined;
        let apiProduct: IAPIProduct;
        let checkUnitData: ICheckCatalogUnit;
        let modulePrice: IModulePriceData;

        for (key in catalogData) {
            checkUnitData = catalogData[key];
            for (sizeId in checkUnitData.sizes) {
                for (corpusMaterialId in checkUnitData.sizes[sizeId].corpusMaterials) {
                    for (facadeMaterialId in checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials) {
                        for (kitId in checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials[facadeMaterialId].kits) {
                            modulePrice = checkUnitData.sizes[sizeId].corpusMaterials[corpusMaterialId].facadeMaterials[facadeMaterialId].kits[kitId].price;
                            if (!modulePrice.errors.length) {
                                importOffer = modulePrice.offer || modulePrice.module?.offer;
                                apiProduct = {
                                    name: this.getCheckUnitTitle(
                                        checkUnitData.unit,
                                        {
                                            length: checkUnitData.sizes[sizeId].width,
                                            height: checkUnitData.sizes[sizeId].height,
                                            width: checkUnitData.sizes[sizeId].depth
                                        },
                                        this.corpusMaterials[corpusMaterialId],
                                        this.facadeMaterials[facadeMaterialId]
                                    ),
                                    type: GOOD_TYPE_MODULE,
                                    vendorCode: importOffer?.vendorCode || '',
                                    guid: importOffer?.externalGuid,
                                    article: importOffer?.article,
                                    catalogCode: checkUnitData.unit.catalogCode,
                                    items: this.getModulePriceApiProductItems(modulePrice),
                                    height: checkUnitData.sizes[sizeId].height,
                                    width: checkUnitData.sizes[sizeId].width,
                                    depth: checkUnitData.sizes[sizeId].depth,
                                    facadeMaterial: this.facadeMaterials[facadeMaterialId].title || '',
                                    corpusMaterial: this.corpusMaterials[corpusMaterialId].title || '',
                                    moduleOffer: importOffer,
                                }
                                apiProducts.push(apiProduct);
                            }
                        }
                    }
                }
            }
        }

        return apiProducts;
    }

    protected getErrorFacadePrice(
        facadeParams: IFacadePriceParam,
        cellIndex: number
    ): {price: IDetailPriceData, error: TError} {
        let error: TError;
        let facadePrice: IFacadePriceData;

        error = {
            id: 'unitKits' + cellIndex,
            message: i18n.t('Ошибка расчета цены фасада') + ' ' + cellIndex
        }
        facadePrice = {
            id: '',
            price: 0,
            errors: [error],
            count: 1,
            note: error.message,
            cell: facadeParams.cell,
            unitId: facadeParams.unitId,
            cellIndex: cellIndex,
            sizes: {width: 0, height: 0, depth: 0},

        };

        return {
            error: error,
            price: facadePrice
        }
    }

    protected calculateFacadesPriceDataByModule(
        facadesParams: IFacadePriceParam[],
        modulePriceData: IModulePriceData
    ): IModulePriceData {
        let facadeParams: IFacadePriceParam;
        let facadePrice: IFacadePriceData | undefined;
        let facadeOffer: IImportOffer | undefined;
        let facadePrices: IFacadePriceData[];
        let prices: { price: number, oldPrice?: number };
        let cellIndex: number;
        let errors: TError[] = [];
        let errorFacadePrice: {price: IDetailPriceData, error: TError};

        facadePrices = [];
        cellIndex = 0;
        for (facadeParams of facadesParams) {
            if (!this.unitKits || !this.unitKits['facade']) {
                errorFacadePrice = this.getErrorFacadePrice(facadeParams, cellIndex);
                facadePrices.push(errorFacadePrice.price);
                errors.push(errorFacadePrice.error);
                cellIndex++;
                continue;
            }
            facadeOffer = KitchenHelper.calculateFacadeOffer(
                facadeParams,
                {width: facadeParams.width, height: facadeParams.height},
                facadeParams.facadeMaterial,
                this.unitKits['facade']
            );
            if (!facadeOffer) {
                errorFacadePrice = this.getErrorFacadePrice(facadeParams, cellIndex);
                facadePrices.push(errorFacadePrice.price);
                errors.push(errorFacadePrice.error);
                cellIndex++;
                continue;
            }
            facadePrice = this.getFacadePriceDataFromOffer(
                facadeOffer,
                facadeParams,
                modulePriceData,
                cellIndex
            );
            facadePrices.push(facadePrice);
            cellIndex++;
        }
        prices = this.calculateFacadesPrice(facadePrices);
        modulePriceData.facades = (modulePriceData.facades || []).concat(facadePrices);
        modulePriceData.price += prices.price;
        if (prices.oldPrice) {
            if (modulePriceData.oldPrice) {
                modulePriceData.oldPrice += prices.oldPrice;
            } else {
                modulePriceData.oldPrice = prices.oldPrice;
            }
        }
        if (errors.length) {
            modulePriceData.errors = modulePriceData.errors.concat(errors);
        }

        return modulePriceData;
    }

    public calculateCheckUnitByModule(checkUnit: ICheckCatalogUnit, technologMaps: ITechnologMaps) {
        if (checkUnit.unit.notPrice) {
            return;
        }
        console.log('calculateCheckUnitByModule!');
        debugger;
    }

    public calculateCheckUnitByDetails(
        checkUnit: ICheckCatalogUnit,
        technologMaps: ITechnologMaps
    ) {
        if (checkUnit.unit.notPrice) {
            return;
        }
        let selectOptions: ICreateObjectSelectOptions;
        let index: string;
        let index2: string;
        let selectItem: TSelectItem;

        selectOptions = KitchenHelper.getCreateObjectDynamicSettings(checkUnit.unit);
        if (Object.keys(selectOptions).length > 0) {
            for (index in selectOptions) {
                if (Array.isArray(selectOptions[index])) {
                    for (selectItem of (selectOptions[index] as TSelectItem[])) {
                        this.setCreateUnitItemByDetailOffers(checkUnit, technologMaps, selectItem, index);
                    }
                } else {
                    for (index2 in selectOptions[index]) {
                        for (selectItem of (selectOptions[index] as { [key: string]: TSelectItem[] })[index2]) {
                            this.setCreateUnitItemByDetailOffers(checkUnit, technologMaps, selectItem, index2, index);
                        }
                    }
                }
            }
        } else {
            this.setCreateUnitItemByDetailOffers(checkUnit, technologMaps);
        }
    }

    protected calculateFacadesPrice(facadePrices: IFacadePriceData[]): { price: number, oldPrice?: number } {
        let facadePrice: IFacadePriceData;
        let detailPrice: IDetailPriceData;
        let price: number = 0;
        let oldPrice: number = 0;

        for (facadePrice of facadePrices) {
            price += facadePrice.price;
            if (facadePrice.oldPrice) {
                oldPrice += facadePrice.oldPrice;
            }
            if (facadePrice.handle) {               
                price += facadePrice.handle.price;
                if (facadePrice.handle.oldPrice) {
                    oldPrice += facadePrice.handle.oldPrice;
                }
            }
            if (facadePrice.hinges) {
                for (detailPrice of facadePrice.hinges) {
                    price += detailPrice.price;
                    if (detailPrice.oldPrice) {
                        oldPrice += detailPrice.oldPrice;
                    }
                }
            }
        }

        return {price: price, oldPrice: oldPrice > 0 && oldPrice > price ? oldPrice : undefined};
    }

    protected getCheckUnitTitle(
        unit: ICreateObjectData,
        sizes: TSizes,
        corpusMaterial?: IMaterialData,
        facadeMaterial?: IFacadeMaterialData): string {
        let title: string;

        title = unit.title + ' (' + i18n.t('ШхВхГ') + ': ' + sizes.length + 'x' + sizes.height + 'x' + sizes.width + ')';
        if (corpusMaterial) {
            title += '; ' + i18n.t('корпус') + ': ' + i18n.t(corpusMaterial.title);
        }
        if (facadeMaterial) {
            title += '; ' + i18n.t('фасад') + ': ' + i18n.t(facadeMaterial.title);
        }

        return title;
    }

    protected calculateModuleTabletopsPriceData(
        unitId: number,
        modulePriceData?: IModulePriceData,
        tabletopParams?: IAccessoryPriceParam[]
    ): IModulePriceData | undefined {
        let externalId: 'externalGuid' | 'vendorCode';
        let cellIndex: number;
        let tabletopParam: IAccessoryPriceParam;
        let offer: IImportOffer | undefined;
        let detailPriceData: IDetailPriceData;
        let offerProductPrice: IProductPrice | undefined;
        let tabletopPrices: IDetailPriceData[] = [];

        if (!tabletopParams || !tabletopParams.length) {
            if (modulePriceData) {
                modulePriceData.tabletops = undefined;
            }
            return modulePriceData;
        }
        if (!modulePriceData) {
            modulePriceData = {
                id: '',
                price: 0,
                errors: [],
                note: 'расчет не предусмотрен',
                cell: PRICE_CELL_MODULE,
                unitId: unitId,
                sizes: {
                    width: 0,
                    height: 0,
                    depth: 0,
                },
            };
        }
        externalId = this.getOfferExternalId();
        cellIndex = 0;
        for (tabletopParam of tabletopParams) {
            offer = KitchenHelper.calculateTabletopOffer(tabletopParam, this.unitKits[GOOD_TYPE_TABLETOP]);
            if (offer) {
                detailPriceData = this.getDetailPriceDataFromOffer(
                    offer,
                    tabletopParam.unitId,
                    tabletopParam.cell,
                    cellIndex
                );
                if (detailPriceData.offer) {
                    offerProductPrice = this.getOfferProductPrice(detailPriceData.offer[externalId]);
                    if (offerProductPrice) {
                        modulePriceData.price += offerProductPrice.price * detailPriceData.count;
                        if (modulePriceData.oldPrice && offerProductPrice.oldPrice) {
                            modulePriceData.oldPrice += (offerProductPrice.oldPrice as number) * detailPriceData.count;
                        }
                    }
                }
            } else {
                detailPriceData = {
                    id: '',
                    price: 0,
                    count: 1,
                    errors: [{id: 'calculateKit', message: 'Не нашли товар столешницы'}],
                    unitId: tabletopParam.unitId,
                    cell: tabletopParam.cell,
                    cellIndex: cellIndex,
                    sizes: {
                        width: tabletopParam.sizes.length,
                        height: tabletopParam.sizes.height,
                        depth: tabletopParam.sizes.width
                    },
                };
                modulePriceData.errors.push({id: 'calculateKit', message: 'Не нашли товар столешницы'});
            }
            cellIndex++;
            tabletopPrices.push(detailPriceData);
        }
        modulePriceData.tabletops = tabletopPrices;

        return modulePriceData;
    }

    protected calculateModulePriceDataStocksInWay(modulePriceData: IModulePriceData) {
        let detailsStocksInWay: IDetailsStocksInWay = {};
        let detailPriceData: IDetailPriceData;
        let facadePriceData: IFacadePriceData;
        let allDates: {[date: string]: string} = {};

        if (modulePriceData.corpus) {
            this.setDetailStocksInWay(
                modulePriceData.corpus,
                detailsStocksInWay,
                allDates
            );
        }
        if (modulePriceData.module) {
            this.setDetailStocksInWay(
                modulePriceData.module,
                detailsStocksInWay,
                allDates
            );
        }
        if (modulePriceData.facades) {
            for (facadePriceData of modulePriceData.facades) {
                this.setDetailStocksInWay(
                    facadePriceData,
                    detailsStocksInWay,
                    allDates
                );
                if (facadePriceData.handle) {
                    this.setDetailStocksInWay(
                        facadePriceData.handle,
                        detailsStocksInWay,
                        allDates
                    );
                }
                if (facadePriceData.hinges) {
                    for (detailPriceData of facadePriceData.hinges) {
                        this.setDetailStocksInWay(
                            detailPriceData,
                            detailsStocksInWay,
                            allDates
                        );
                    }
                }
            }
        }
        if (modulePriceData.tabletops) {
            for (detailPriceData of modulePriceData.tabletops) {
                this.setDetailStocksInWay(
                    detailPriceData,
                    detailsStocksInWay,
                    allDates
                );
            }
        }
        modulePriceData.stocksInWay = this.calculateModuleStocksInWay(
            allDates,
            detailsStocksInWay
        );
    }

    protected calculateModuleStocksInWay(
        allDates: {[date: string]: string},
        detailsStocksInWay: IDetailsStocksInWay
    ): IStocksInWay | undefined {
        let stocksInWay: IStocksInWay = {};
        let dateStocksInWay: IStockInWay;
        let date: string;
        let sortedDates: string[];
        let offerId: string;
        let itemStock: number;
        let itemCount: number;
        let dateModuleAmount: number;
        let overStocks: {[offerId: string]: number} = {};

        if (!Object.keys(allDates).length) {
            return undefined;
        }
        sortedDates = Object.keys(allDates).sort((a, b) => {
            const stringA: string = a.split('.').reverse().join('.');
            const stringB: string = b.split('.').reverse().join('.');
            if (stringA > stringB) {
                return -1;
            }
            if (stringA < stringB) {
                return 1;
            }
            return 0;
        })
        for (date of sortedDates) {
            dateStocksInWay = {
                amount: 0,
                date: date,
                offerId: '',
                goodId: '',
                storeId: ''
            };
            dateModuleAmount = Infinity;
            overStocks = {};
            for (offerId in detailsStocksInWay) {
                itemStock = detailsStocksInWay[offerId].stock;
                itemCount = 0;
                if (detailsStocksInWay[offerId].stocksInWay[date]) {
                    itemStock += detailsStocksInWay[offerId].stocksInWay[date].amount;
                }
                itemCount = Math.floor(itemStock/detailsStocksInWay[offerId].count);
                overStocks[offerId] = itemStock % detailsStocksInWay[offerId].count;
                if (itemCount < dateModuleAmount) {
                    dateModuleAmount = itemCount;
                }
            }
            if (Number.isFinite(dateModuleAmount) && dateModuleAmount > 0) {
                dateStocksInWay.amount = dateModuleAmount;
                for (offerId in detailsStocksInWay) {
                    detailsStocksInWay[offerId].stock = overStocks[offerId];
                }
                stocksInWay[date] = dateStocksInWay;
            }
        }

        return (Object.keys(stocksInWay).length) ? stocksInWay : undefined;
    }

    protected setDetailStocksInWay(
        detailPriceData: IDetailPriceData,
        detailsStocksInWay: IDetailsStocksInWay,
        allDates: {[key: string]: string}
    ) {
        let date: string;
        if (detailPriceData.offer && detailPriceData.stocksInWay) {
            for (date of Object.keys(detailPriceData.stocksInWay)) {
                allDates[date] = date;
            }
            if (!detailsStocksInWay[detailPriceData.offer.id]) {
                detailsStocksInWay[detailPriceData.offer.id] = {
                    stocksInWay: detailPriceData.stocksInWay,
                    count: detailPriceData.count,
                    stock: detailPriceData.stock || 0,
                };
            } else {
                detailsStocksInWay[detailPriceData.offer.id].count += detailPriceData.count;
            }
        }
    }

    protected calculateModuleExtraOffersPriceData(
        unitId: number,
        modulePriceData?: IModulePriceData,
        extraOffersPriceParams?: IOfferPriceParam[]
    ): IModulePriceData | undefined {
        let offerPriceParam: IOfferPriceParam;
        let extraOffers: ICorpusPriceData[] | undefined;
        let externalId: 'externalGuid' | 'vendorCode';
        let offer: IImportOffer | undefined;
        let cellIndex: number;
        let offerProductPrice: IProductPrice | undefined;

        if (!extraOffersPriceParams || !extraOffersPriceParams.length) {
            if (modulePriceData) {
                modulePriceData.extraOffers = undefined;
            }
            return modulePriceData;
        }

        if (!modulePriceData) {
            modulePriceData = {
                id: '',
                price: 0,
                errors: [],
                note: i18n.t('расчет не предусмотрен'),
                cell: PRICE_CELL_MODULE,
                unitId: unitId,
                sizes: {
                    width: 0,
                    height: 0,
                    depth: 0,
                },
            };
        }
        externalId = this.getOfferExternalId();
        cellIndex = 0;
        for (offerPriceParam of extraOffersPriceParams) {
            offer = this.getOfferById(offerPriceParam.id);
            if (offer) {
                if (!extraOffers) {
                    extraOffers = [];
                }
                offerProductPrice = this.getOfferProductPrice(offer[externalId]);
                if (!offerProductPrice) {
                    extraOffers.push({
                        id: offer[externalId],
                        price: 0,
                        errors: [],
                        note: i18n.t('Идет расчет...'),
                        offer: offer,
                        count: offerPriceParam.count,
                        cell: PRICE_CELL_EXTRA_OFFERS,
                        unitId: unitId,
                        cellIndex: cellIndex,
                        sizes: {
                            width: offer.width,
                            height: offer.height,
                            depth: offer.depth,
                        },
                    });
                } else {
                    modulePriceData.price += offerProductPrice.price * offerPriceParam.count;
                    if (modulePriceData.oldPrice && offerProductPrice.oldPrice) {
                        modulePriceData.oldPrice += (offerProductPrice.oldPrice as number) * offerPriceParam.count;
                    }
                    extraOffers.push({
                        id: offer[externalId],
                        price: offerProductPrice.price,
                        oldPrice: offerProductPrice.oldPrice,
                        active: offerProductPrice.active,
                        onlyOrder: offerProductPrice.onlyOrder,
                        stocksInWay: offerProductPrice.stocksInWay,
                        stock: offerProductPrice.stock,
                        errors: [],
                        offer: offer,
                        count: offerPriceParam.count,
                        cell: PRICE_CELL_EXTRA_OFFERS,
                        unitId: unitId,
                        cellIndex: cellIndex,
                        sizes: {
                            width: offer.width,
                            height: offer.height,
                            depth: offer.depth,
                        },
                    });
                }
                cellIndex++;
            }
        }
        modulePriceData.extraOffers = extraOffers;

        return modulePriceData;
    }

    protected setUniqueProductItem(
        detailPriceData: IDetailPriceData,
        items: { [key: string]: IAPIProductItem }
    ) {
        if (!detailPriceData.offer) {
            return;
        }
        if (items[detailPriceData.offer[this.getOfferExternalId()]]) {
            items[detailPriceData.offer[this.getOfferExternalId()]].count += detailPriceData.count;
        } else {
            items[detailPriceData.offer[this.getOfferExternalId()]] = {
                vendorCode: detailPriceData.offer.vendorCode,
                guid: detailPriceData.offer.externalGuid,
                count: detailPriceData.count
            }
        }
    }

    private calculateCheckUnit(
        checkUnit: ICheckCatalogUnit,
        calculateType: TCatalogCalculateType,
        technologMaps: ITechnologMaps
        ) {
        if (checkUnit.unit.notPrice) {
            return;
        }
        let selectOptions: ICreateObjectSelectOptions;
        let index: string;
        let index2: string;
        let selectItem: TSelectItem;

        selectOptions = KitchenHelper.getCreateObjectDynamicSettings(checkUnit.unit);
        if (Object.keys(selectOptions).length > 0) {
            for (index in selectOptions) {
                if (Array.isArray(selectOptions[index])) {
                    for (selectItem of (selectOptions[index] as TSelectItem[])) {
                        this.setCheckUnitItemOffers(calculateType, checkUnit, technologMaps, selectItem, index);
                    }
                } else {
                    for (index2 in selectOptions[index]) {
                        for (selectItem of (selectOptions[index] as { [key: string]: TSelectItem[] })[index2]) {
                            this.setCheckUnitItemOffers(calculateType, checkUnit, technologMaps, selectItem, index2, index);
                        }
                    }
                }
            }
        } else {
            this.setCheckUnitItemOffers(calculateType, checkUnit, technologMaps);
        }
    }

    private getCreateUnitSideTypes(createUnit: ICreateObjectData): TDirectionSideType[] {
        let option: IOption | IOptionGroup;
        let selectOption: IOptionSelect | IOptionRadioButton;
        let item: TSelectItem;
        let sideTypes: TDirectionSideType[] = [];

        for (option of createUnit.options) {
            if (option.id === 'sideType' && 'items' in option) {
                selectOption = option as IOptionSelect | IOptionRadioButton;
                for (item of selectOption.items) {
                    if (item.disabled) {
                        continue;
                    }
                    sideTypes.push(item.id as TDirectionSideType);
                }
                break;
            }
        }
        // if (createUnit.uid === "shkaf-1dver-butilochnica-visokiy") {
        //     console.log('sideTypes', sideTypes);
        // }
        if (!sideTypes.length) {
            sideTypes.push(SIDE_TYPE_DEFAULT);
        }

        return sideTypes;
    }

    private setCheckUnitItemOffers(
        calculateType: TCatalogCalculateType,
        checkUnit: ICheckCatalogUnit,
        technologMaps: ITechnologMaps,
        selectItem?: TSelectItem,
        itemName?: string,
        groupName?: string
    ) {
        let createOptions: any;
        let priceParams: IModulePriceParams | undefined;
        let modulePrice: IModulePriceData | undefined;
        let index: string;
        let index2: string;
        let corpusMaterial: IMaterialData;
        let facadeMaterial: IFacadeMaterialData;
        let sizes: TSizes | undefined;
        let sizesId: string;
        let unitSizes: TCreateUnitSizes;
        let sideTypes: TDirectionSideType[];

        if (!this.unitKits) {
            return;
        }
        createOptions = KitchenHelper.getDefaultOptions(checkUnit.unit);
        if (selectItem && itemName) {
            if (groupName) {
                createOptions[groupName][itemName] = selectItem.id;
            } else {
                createOptions[itemName] = selectItem.id;
            }
        }
        unitSizes = this.calculateCreateObjectSizes(createOptions);
        if (unitSizes.unit) {
            sizes = unitSizes.unit;
        }
        if (!sizes && unitSizes.corpus) {
            sizes = unitSizes.corpus;
        }
        if (!sizes) {
            return;
        }
        sizesId = sizes.length + '_' + sizes.height + '_' + sizes.width;

        if (!checkUnit.sizes[sizesId]) {
            checkUnit.sizes[sizesId] = {
                width: sizes.length,
                height: sizes.height,
                depth: sizes.width,
                corpusMaterials: {}
            };
        }
        sideTypes = this.getCreateUnitSideTypes(checkUnit.unit);
        for (index in this.corpusMaterials) {
            corpusMaterial = this.corpusMaterials[index];
            checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id] = {
                material: corpusMaterial.id,
                facadeMaterials: {}
            }
            for (index2 in this.facadeMaterials) {
                facadeMaterial = this.facadeMaterials[index2];
                if (checkUnit.unit.disableFacades &&
                    checkUnit.unit.disableFacades.includes(facadeMaterial.facade)) {
                    continue;
                }
                checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id].facadeMaterials[facadeMaterial.id] = {
                    facadeMaterial: facadeMaterial.id,
                    kits: {}
                }
                priceParams = this.calculateCreateObjectPriceParams(
                    checkUnit.unit,
                    createOptions,
                    technologMaps,
                    corpusMaterial,
                    facadeMaterial.id,
                    undefined,
                    undefined,
                    sideTypes[0]
                );
                if (!priceParams) {
                    return true;
                }
                switch (calculateType) {
                    case CATALOG_CALCULATE_TYPE_DETAILS:
                        modulePrice = this.calculatePriceByDetails(priceParams);
                        break;
                    case CATALOG_CALCULATE_TYPE_MODULE:
                        modulePrice = this.calculatePriceByModule(priceParams);
                        break;
                }
                checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id].facadeMaterials[facadeMaterial.id].kits['default'] = {
                    createOptions: createOptions,
                    params: priceParams,
                    price: modulePrice ? modulePrice : {
                        id: '',
                        price: 0,
                        errors: [{id: 'error', message: 'Ошибка'}],
                        unitId: 0,
                        cell: PRICE_CELL_MODULE,
                        sizes: {
                            width: priceParams.width,
                            height: priceParams.height,
                            depth: priceParams.depth,
                        },
                    }
                }
            }
        }
    }

    private getCatalogCorpusMaterials(createOptions: any): IMaterialData[] {
        let index: string;
        let corpusMaterials: IMaterialData[] = [];
        const noneCorpusMaterial: IMaterialData = {id: NONE_MATERIAL, title: i18n.t('Без материала')};

        if (createOptions.corpusMaterial === NONE_MATERIAL ||
            (createOptions.corpus && createOptions.corpus.material === NONE_MATERIAL)) {
            return [noneCorpusMaterial];
        }
        for (index in this.corpusMaterials) {
            if (this.corpusMaterials[index].isCustom) {
                continue;
            }
            corpusMaterials.push(this.corpusMaterials[index]);
        }

        return corpusMaterials.length ? corpusMaterials : [noneCorpusMaterial];
    }

    private getCatalogFacadeMaterials(createOptions: any, facade?: IFacadeData): IFacadeMaterialData[] {
        const noneFacadeMaterial: IFacadeMaterialData = {
            id: NONE_MATERIAL,
            title: i18n.t('Без материала'),
            facade: NONE_MATERIAL,
            material: NONE_MATERIAL
        };

        if (createOptions.facadeMaterial === NONE_MATERIAL ||
            !createOptions.facades || !createOptions.facades.length) {
            return [noneFacadeMaterial];
        }
        let facadeMaterials: IFacadeMaterialData[] = [];
        let facadeMaterial: IFacadeMaterialData;
        let index: string;

        for (index in this.facadeMaterials) {
            facadeMaterial = this.facadeMaterials[index];
            if ((!facade || (facade && facade.id === facadeMaterial.facade)) && !facadeMaterial.isCustom) {
                facadeMaterials.push(facadeMaterial);
            }
        }

        return facadeMaterials.length ? facadeMaterials : [noneFacadeMaterial];
    }

    private setCreateUnitItemByDetailOffers(
        checkUnit: ICheckCatalogUnit,
        technologMaps: ITechnologMaps,
        selectItem?: TSelectItem,
        itemName?: string,
        groupName?: string
    ) {
        let createOptions: any;
        let itemCreateOptions: any;
        let priceParams: IModulePriceParams | undefined;
        let modulePrice: IModulePriceData | undefined;
        let corpusMaterials: IMaterialData[];
        let corpusMaterial: IMaterialData;
        let facadeMaterials: IFacadeMaterialData[];
        let facadeMaterial: IFacadeMaterialData;
        let sizes: TSizes | undefined;
        let sizesId: string;
        let unitSizes: TCreateUnitSizes;

        if (!this.unitKits) {
            return;
        }
        createOptions = CommonHelper.deepCopy(KitchenHelper.getDefaultOptions(checkUnit.unit));
        if (selectItem && itemName) {
            if (groupName) {
                createOptions[groupName][itemName] = selectItem.id;
            } else {
                createOptions[itemName] = selectItem.id;
            }
        }
        unitSizes = KitchenHelper.calculateCreateObjectSizes(createOptions);
        if (unitSizes.unit) {
            sizes = unitSizes.unit;
        }
        if (!sizes && unitSizes.corpus) {
            sizes = unitSizes.corpus;
        }
        if (!sizes) {
            return;
        }
        sizesId = sizes.length + '_'+ sizes.height + '_' + sizes.width;

        if (!checkUnit.sizes[sizesId]) {
            checkUnit.sizes[sizesId] = {
                width: sizes.length,
                height: sizes.height,
                depth: sizes.width,
                corpusMaterials: {}
            };
        }
        corpusMaterials = this.getCatalogCorpusMaterials(createOptions);
        facadeMaterials = this.getCatalogFacadeMaterials(createOptions);

        for (corpusMaterial of corpusMaterials) {
            if (corpusMaterial.isCustom) {
                continue;
            }
            checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id] = {
                material: corpusMaterial.id,
                facadeMaterials: {}
            }
            for (facadeMaterial of facadeMaterials) {
                if (facadeMaterial.isCustom) {
                    continue;
                }
                if (checkUnit.unit.disableFacades &&
                    checkUnit.unit.disableFacades.includes(facadeMaterial.facade)) {
                    continue;
                }
                if (checkUnit.unit.enableFacades &&
                    !checkUnit.unit.enableFacades.includes(facadeMaterial.facade)) {
                    continue;
                }
                checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id].facadeMaterials[facadeMaterial.id] = {
                    facadeMaterial: facadeMaterial.id,
                    kits: {}
                }
                priceParams = this.calculateCreateObjectPriceParams(
                    checkUnit.unit,
                    createOptions,
                    technologMaps,
                    corpusMaterial,
                    facadeMaterial.id,
                    undefined,
                    undefined,
                    undefined
                );
                itemCreateOptions = this.calculateCreateObjectCreateOptions(createOptions, corpusMaterial, facadeMaterial);
                if (!priceParams) {
                    return true;
                }
                modulePrice = this.calculatePriceByDetails(priceParams);
                if (modulePrice) {
                    checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id].facadeMaterials[facadeMaterial.id].kits['default'] = {
                        price: modulePrice,
                        params: priceParams,
                        createOptions: itemCreateOptions
                    };
                } else {
                    checkUnit.sizes[sizesId].corpusMaterials[corpusMaterial.id].facadeMaterials[facadeMaterial.id].kits['default'] = {
                        price: {
                            id: '',
                            price: 0,
                            errors: [{id: 'error', message: 'Ошибка'}],
                            unitId: 0,
                            sizes: {
                                width: priceParams.width,
                                height: priceParams.height,
                                depth: priceParams.depth,
                            },
                            cell: PRICE_CELL_MODULE,
                        },
                        params: priceParams,
                        createOptions: itemCreateOptions
                    };
                }
            }
        }
    }

    public static calculateFacadeOffer(
        facadeData: ISaveFacadeData | IFacadePriceParam,
        sizes: TFacadeSizes,
        facadeMaterial: string,
        offers: IImportOffer[],
        disableFacadeMaterials?: boolean): IImportOffer | undefined {
        let offer: IImportOffer;
        let simularFacadeOffer: IImportOffer | undefined;      

        for (offer of offers) {
            //
            if (offer.width === sizes.width &&
                offer.height === sizes.height &&
                ((!disableFacadeMaterials && facadeMaterial === offer.facadeMaterial) ||
                    (disableFacadeMaterials && offer.facadeMaterial.includes(facadeMaterial))) &&
                offer.functionalType === facadeData.functionalType &&
                offer.facadeModelType === facadeData.modelType &&
                this.compareSideType(offer.sideType, facadeData.sideType)
            ) {
                return offer;
            }
        }
        if (facadeData.canStretch && facadeData.isStretch) {
            for (offer of offers) {
                if ((offer.width >= sizes.width && (!simularFacadeOffer || simularFacadeOffer.width >= offer.width)) &&
                    offer.height >= sizes.height && (!simularFacadeOffer || simularFacadeOffer.height >= offer.height) &&
                    ((!disableFacadeMaterials && facadeMaterial === offer.facadeMaterial) ||
                        (disableFacadeMaterials && offer.facadeMaterial.includes(facadeMaterial))) &&
                    offer.functionalType === facadeData.functionalType &&
                    offer.facadeModelType === facadeData.modelType &&
                    this.compareSideType(offer.sideType, facadeData.sideType)
                ) {
                    simularFacadeOffer = offer;
                }
            }
            if (simularFacadeOffer) {
                return simularFacadeOffer;
            }
        }

        return undefined;
    }

    public static getDefaultOptions(objectData: ICreateObjectData, width?: number, corpusWidth?: number): any {
        let options: any = {};
        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let selectItem: TSelectItem;

        options.uid = objectData.uid;
        options.name = objectData.title;
        options.image = objectData.image;
        options.catalogCode = objectData.catalogCode;
        options.calculateType = objectData.calculateType;
        options.checkCatalogType = objectData.checkCatalogType;
        options.level = objectData.level;
        options.isSameWidthDepth = objectData.isSameWidthDepth;
        options.notPrice = objectData.notPrice;
        options.availableSideTypes = objectData.availableSideTypes;
        options.canStretch = objectData.canStretch;
        options.isStretch = objectData.isStretch;
        for (option of objectData.options) {
            if ('isGroup' in option) {
                if (!options[option.id]) {
                    options[option.id] = {};
                }
                for (childOption of option.options) {
                    if ('defaultValue' in childOption) {
                        options[option.id][childOption.id] = childOption.i18n ? i18n.t(childOption.defaultValue) : childOption.defaultValue;
                    }
                    if ('value' in childOption) {
                        options[option.id][childOption.id] = childOption.value;
                    }
                    if (option.id === 'sizes' && childOption.id === 'width') {
                        if (width) {
                            options[option.id][childOption.id] = width;
                        }
                        if (objectData.disableSelects && objectData.disableSelects['sizes_width'] &&
                            objectData.disableSelects['sizes_width'].includes('' + options[option.id][childOption.id])) {
                            if ('items' in childOption) {
                                for (selectItem of (childOption as IOptionSelect).items) {
                                    if (!objectData.disableSelects['sizes_width'].includes(selectItem.id)) {
                                        options[option.id][childOption.id] = selectItem.id;
                                        break;
                                    }
                                }
                            }
                        }

                    }
                    if (option.id === 'corpus' && childOption.id === 'width') {
                        if (corpusWidth) {
                            options[option.id][childOption.id] = corpusWidth;
                        }
                        if (objectData.disableSelects && objectData.disableSelects['corpus_width'] &&
                            objectData.disableSelects['corpus_width'].includes('' + options[option.id][childOption.id])) {
                            if ('items' in childOption) {
                                for (selectItem of (childOption as IOptionSelect).items) {
                                    if (!objectData.disableSelects['corpus_width'].includes(selectItem.id)) {
                                        options[option.id][childOption.id] = selectItem.id;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if ('defaultValue' in option) {
                    options[option.id] = option.i18n ? i18n.t(option.defaultValue) : option.defaultValue;
                }
                if ('value' in option) {
                    options[option.id] = option.value;
                }
                if (option.id === 'sizes' && option.type === OPTION_TYPE_JSON) {
                    if (width) {
                        options[option.id].width = width;
                    }
                    if (objectData.disableSelects && objectData.disableSelects['width'] &&
                        objectData.disableSelects['width'].includes('' + options[option.id].width)) {
                        if ('items' in option) {
                            for (selectItem of (option as IOptionSelect).items) {
                                if (!objectData.disableSelects['width'].includes(selectItem.id)) {
                                    options[(option as IOptionSelect).id].width = selectItem.id;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (objectData.isAccessory) {
            if (options.sizes && 'height' in options.sizes &&
                objectData.availableHeights && !objectData.availableHeights.includes('' + options.sizes.height)) {
                options.sizes.height = +objectData.availableHeights[0];
            }
        }
        if (options.tabletopMaterial && options.tabletops && options.tabletops.length) {
            options.tabletops = options.tabletops.map((item: ISaveKUnitDetailData) => {
                if (item.includeModulePrice) {
                    item.material = options.tabletopMaterial;
                }
                return item;
            });
        }

        return options;
    }

    public static calculateCorpusOffers(
        corpusParams: ICorpusPriceParams,
        unitKits: IUnitKits
    ): ICorpusOffers | undefined {
        if (!unitKits) {
            return undefined;
        }
        let index: string;
        let offer: IImportOffer;
        let corpusOffer: IImportOffer | undefined;
        let simularCorpusOffer: IImportOffer | undefined;
        let furnitureParams: IFurniturePriceParams;
        let furnitureOffers: IFurnitureOffer[] = [];
        let furnitureOffer: IFurnitureOffer | undefined;

        if (!unitKits[corpusParams.catalogCode]) {
            return undefined;
        }
        for (offer of unitKits[corpusParams.catalogCode]) {
            if (offer.width === corpusParams.width &&
                offer.height === corpusParams.height &&
                offer.depth === corpusParams.depth &&
                (!corpusParams.material || offer.corpusMaterial === corpusParams.material) &&
                (!corpusParams.panelMaterial || offer.panelMaterial === corpusParams.panelMaterial) &&
                (!corpusParams.frameMaterial || offer.frameMaterial === corpusParams.frameMaterial) &&
                (!corpusParams.sideType || this.compareSideType(offer.sideType, corpusParams.sideType))) {
                corpusOffer = offer;
                break;
            }
        }
        if (!corpusOffer && corpusParams.canStretch && corpusParams.isStretch) {
            for (offer of unitKits[corpusParams.catalogCode]) {
                if ((offer.width >= corpusParams.width && (!simularCorpusOffer || simularCorpusOffer.width >= offer.width)) &&
                    (offer.height >= corpusParams.height && (!simularCorpusOffer || simularCorpusOffer.height >= offer.height)) &&
                    (offer.depth >= corpusParams.depth && (!simularCorpusOffer || simularCorpusOffer.depth >= offer.depth)) &&
                    (!corpusParams.material || offer.corpusMaterial === corpusParams.material) &&
                    (!corpusParams.panelMaterial || offer.panelMaterial === corpusParams.panelMaterial) &&
                    (!corpusParams.frameMaterial || offer.frameMaterial === corpusParams.frameMaterial)) {
                    simularCorpusOffer = offer;
                }
            }
            if (simularCorpusOffer) {
                corpusOffer = simularCorpusOffer;
            }
        }
        if (!corpusOffer) {
            return undefined;
        }
        if (corpusParams.furniture) {
            for (index in corpusParams.furniture) {
                furnitureParams = corpusParams.furniture[index];
                if (!unitKits[furnitureParams.catalogCode]) {
                    return undefined;
                }
                furnitureOffer = undefined;
                for (offer of unitKits[furnitureParams.catalogCode]) {
                    if (offer.width === furnitureParams.width &&
                        offer.height === furnitureParams.height &&
                        offer.depth === furnitureParams.depth &&
                        offer.functionalType === furnitureParams.functionalType) {
                        furnitureOffer = {
                            index: index,
                            offer: offer,
                            unitId: furnitureParams.unitId,
                            cell: furnitureParams.cell,
                        };
                        break;
                    }
                }
                if (!furnitureOffer) {
                    return undefined;
                } else {
                    furnitureOffers.push(furnitureOffer);
                }
            }
        }

        return {
            offer: corpusOffer,
            unitId: corpusParams.unitId,
            cell: corpusParams.cell,
            furniture: furnitureOffers,
        };
    }

    public static compareSideType(offerSideType: TDirectionSideType, sideType?: TDirectionSideType): boolean {
        if (sideType === undefined ||
            offerSideType === SIDE_TYPE_DEFAULT ||
            sideType === SIDE_TYPE_DEFAULT) {
            return true;
        }

        return offerSideType === sideType;
    }

    public static getOfferExternalId(appConfig: IAppConfig): 'externalGuid' | 'vendorCode' {
        if (appConfig.catalog.offerExternalId) {
            return appConfig.catalog.offerExternalId;
        }
        return 'vendorCode';
    }

    public static calculateCreateObjectSizes(createOptions: any): TCreateUnitSizes {
        let unitSizes: TSizes | undefined;
        let corpusSizes: TSizes | undefined;

        if (createOptions.corpus &&
            createOptions.corpus.width &&
            createOptions.corpus.height &&
            createOptions.corpus.depth) {
            corpusSizes = {
                length: +createOptions.corpus.width,
                height: +createOptions.corpus.height,
                width: +createOptions.corpus.depth
            }
        }
        if (createOptions.sizes &&
            createOptions.sizes.width &&
            createOptions.sizes.height &&
            createOptions.sizes.depth) {
            unitSizes = {
                length: +createOptions.sizes.width,
                height: +createOptions.sizes.height,
                width: +createOptions.sizes.depth
            }
        }
        if (corpusSizes &&
            (isNaN(corpusSizes.length) ||
                isNaN(corpusSizes.width) ||
                isNaN(corpusSizes.height))) {
            corpusSizes = undefined;
        }
        if (unitSizes &&
            (isNaN(unitSizes.length) ||
                isNaN(unitSizes.width) ||
                isNaN(unitSizes.height))) {
            unitSizes = undefined;
        }

        return {
            unit: unitSizes,
            corpus: corpusSizes
        }
    }

    public static getCreateObjectCalculateType(
        createObject: ICreateObjectData,
        appConfig: IAppConfig,
        technologMaps: ITechnologMaps
    ): TCatalogCalculateType {
        if (createObject.calculateType) {
            return createObject.calculateType;
        }
        const facadeId: string | undefined = this.getCreateObjectFacadeId(createObject);

        let technologMap: ITechnologMap | undefined;

        if (facadeId && technologMaps[facadeId]) {
            technologMap = technologMaps[facadeId]
        }
        if (!technologMap && technologMaps['default']) {
            technologMap = technologMaps['default'];
        }

        if(technologMap && technologMap.calculateType) {
            return technologMap.calculateType
        }

        return appConfig.catalog.calculateType;
    }

    // TODO добавить выдачу ид фасада из данных по созданию модуля
    public static getCreateObjectFacadeId(createObject: ICreateObjectData): string | undefined {
        return undefined;
    }

    public static calculateCorpusParams(
        createOptions: any,
        corpusMaterial: string | undefined,
        parentData: TDataForSizeByParent
    ): ICorpusPriceParams | undefined {
        let corpusParams: ICorpusPriceParams | undefined;
        let width: number | undefined;
        let height: number | undefined;
        let depth: number | undefined;
        let goodData: IFurniturePriceParams;

        if (corpusMaterial === NONE_MATERIAL) {
            return undefined;
        }

        if (createOptions.corpus) {
            if (!isNaN(+createOptions.corpus.width)) {
                width = +createOptions.corpus.width;
            }
            if (!isNaN(+createOptions.corpus.depth)) {
                depth = +createOptions.corpus.depth;
            }
            if (!isNaN(+createOptions.corpus.height)) {
                height = +createOptions.corpus.height;
            }
            if (createOptions.isSameWidthDepth && width && !isNaN(+width)) {
                depth = width;
            }
            if (width && depth && height && corpusMaterial) {
                corpusParams = {
                    catalogCode: createOptions.corpus.catalogCode,
                    width: width,
                    depth: depth,
                    height: height,
                    material: corpusMaterial,
                    cell: PRICE_CELL_CORPUS,
                    unitId: 0,
                    canStretch: createOptions.canStretch,
                    isStretch: createOptions.isStretch,
                    sideType: createOptions.sideType,
                    frameMaterial: createOptions.frameMaterial,
                    panelMaterial: createOptions.panelMaterial,
                };
                if (createOptions.corpus.furniture && Array.isArray(createOptions.corpus.furniture)) {
                    corpusParams.furniture = [];
                    for (goodData of createOptions.corpus.furniture) {
                        corpusParams.furniture.push({
                            width: this.calculateSizeByParent(goodData.width, corpusParams.width, parentData),
                            depth: this.calculateSizeByParent(goodData.depth, corpusParams.depth, parentData),
                            height: this.calculateSizeByParent(goodData.height, corpusParams.height, parentData),
                            catalogCode: goodData.catalogCode,
                            functionalType: goodData.functionalType,
                            kitCode: goodData.kitCode,
                            kitAmount: goodData.kitAmount,
                            furnitureType: goodData.furnitureType,
                            count: goodData.count,
                            cell: PRICE_CELL_FURNITURE,
                            unitId: 0,
                            canStretch: createOptions.canStretch,
                            isStretch: createOptions.isStretch,
                        });
                    }
                }
            }
        }

        return corpusParams;
    }

    public static calculateFacadesParams(
        createOptions: any,
        sideType: TDirectionSideType,
        facadeMaterial: string | undefined,
        selfFacadeMaterial: string | undefined,
        parentData: TDataForSizeByParent,
        corpusSizes: TSizes,
        corpusFacadeMaterial?: string,
        secondFacadeMaterial?: string
    ): IFacadePriceParam[] {
        let facadesParams: IFacadePriceParam[] = [];
        let facadeData: ISaveFacadeData;
        let index: string;
        let itemFacadeMaterial: string;
        let sizes: TFacadeSizes;
        let facadeSideType: TDirectionSideType;
        let realSizes: TFacadeSizes;
        let calculateSizes: TOptionalFacadeSizes | undefined;
        let sizeId: string;
        let calculateType: TFacadeCalculateType | undefined;
        let functionalType: string | undefined;
        let itemSideType: TDirectionSideType | undefined;
        let modelType: TFacadeModelType | undefined;

        if (createOptions.facades && ((facadeMaterial && facadeMaterial !== NONE_MATERIAL) ||
            (selfFacadeMaterial && selfFacadeMaterial !== NONE_MATERIAL))) {
            for (index in createOptions.facades) {
                facadeData = createOptions.facades[index] as ISaveFacadeData;
                realSizes = this.calculateFacadeSizes(
                    facadeData.initSizes,
                    corpusSizes,
                    parentData,
                    facadeData.gap
                );
                sizeId = realSizes.width + '_' + realSizes.height;
                calculateType = facadeData.calculateType;
                if (facadeData.sizesCalculateData &&
                    facadeData.sizesCalculateData[sizeId] &&
                    facadeData.sizesCalculateData[sizeId].calculateType !== undefined) {
                    calculateType = facadeData.sizesCalculateData[sizeId].calculateType as TFacadeCalculateType;
                }
                if (calculateType === FACADE_CALCULATE_NONE) {
                    continue;
                }
                functionalType = facadeData.functionalType;
                if (facadeData.sizesCalculateData &&
                    facadeData.sizesCalculateData[sizeId] &&
                    facadeData.sizesCalculateData[sizeId].functionalType !== undefined) {
                    functionalType = facadeData.sizesCalculateData[sizeId].functionalType as string;
                }
                itemSideType = facadeData.sideType;
                if (facadeData.sizesCalculateData &&
                    facadeData.sizesCalculateData[sizeId] &&
                    facadeData.sizesCalculateData[sizeId].sideType !== undefined) {
                    itemSideType = facadeData.sizesCalculateData[sizeId].sideType as TDirectionSideType;
                }
                modelType = facadeData.modelType;
                if (facadeData.sizesCalculateData &&
                    facadeData.sizesCalculateData[sizeId] &&
                    facadeData.sizesCalculateData[sizeId].modelType !== undefined) {
                    modelType = facadeData.sizesCalculateData[sizeId].modelType as TFacadeModelType;
                }
                calculateSizes =  facadeData.calculateSizes;
                if (facadeData.sizesCalculateData &&
                    facadeData.sizesCalculateData[sizeId] &&
                    facadeData.sizesCalculateData[sizeId].calculateSizes !== undefined) {
                    calculateSizes = facadeData.sizesCalculateData[sizeId].calculateSizes as TOptionalFacadeSizes;
                }
                sizes = this.calculateFacadeSizes(
                    facadeData.initSizes,
                    corpusSizes,
                    parentData,
                    facadeData.gap,
                    calculateSizes
                );
                switch (facadeData.facadeMaterialType) {
                    case FACADE_MATERIAL_TYPE_CORPUS_MATERIAL:
                        if (corpusFacadeMaterial) {
                            itemFacadeMaterial = corpusFacadeMaterial;
                        } else {
                            itemFacadeMaterial = NONE_MATERIAL;
                        }
                        break;
                    case FACADE_MATERIAL_TYPE_SECOND:
                        if (secondFacadeMaterial) {
                            itemFacadeMaterial = secondFacadeMaterial;
                        } else {
                            itemFacadeMaterial = NONE_MATERIAL;
                        }
                        break;
                    case FACADE_MATERIAL_TYPE_DEFAULT:
                    default:
                        itemFacadeMaterial = calculateType === FACADE_CALCULATE_SELF_AMOUNT && selfFacadeMaterial !== undefined ?
                            selfFacadeMaterial : facadeData.facadeMaterial || facadeMaterial || NONE_MATERIAL;
                        break;
                }
                if (facadeData.disableSideTypes) {
                    facadeSideType = itemSideType;
                } else {
                    facadeSideType = (!facadeData.availableSideTypes || facadeData.availableSideTypes.includes(sideType)) ?
                        sideType : (facadeData.availableSideTypes && facadeData.availableSideTypes[0] ?
                            facadeData.availableSideTypes[0] : SIDE_TYPE_DEFAULT);
                }
                if (itemFacadeMaterial !== NONE_MATERIAL) {
                    facadesParams.push({
                        facadeMaterial: itemFacadeMaterial,
                        width: sizes.width,
                        height: sizes.height,
                        functionalType: functionalType,
                        modelType: modelType,
                        sideType: facadeSideType,
                        calculateType: calculateType,
                        unitId: 0,
                        cell: PRICE_CELL_FACADE,
                        canStretch: createOptions.canStretch,
                        isStretch: createOptions.isStretch,
                        facadeMaterialType: facadeData.facadeMaterialType,
                        for: facadeData.for,
                        handle: this.getHandlePriceParams(facadeData),
                        hinges: this.getHingesPriceParams(facadeData),
                    });
                } else {
                    debugger;
                    console.log('ERROR calculateFacadeParams', facadeData);
                }

            }
        }

        return facadesParams;
    }

    public static getHandlePriceParams(facadeData: ISaveFacadeData): IHandlePriceParams | undefined {
        if (!facadeData.handle) {
            return undefined;
        }
        let handle: ISaveHandleData = facadeData.handle;
        if (!handle || !handle.calculate) {
            return undefined;
        }

        return {
            count: 1,
            depth: handle.sizes ? handle.sizes.depth : 0,
            width: handle.sizes ? handle.sizes.width : 0,
            height: handle.sizes ? handle.sizes.height : 0,
            catalogCode: 'handle',
            functionalType: FURNITURE_FT_HANDLE,
            furnitureType: handle.furnitureType || FURNITURE_TYPE_NONE,
            unitId: 0,
            cell: PRICE_CELL_HANDLE,
        }
    }

    public static getHingesPriceParams(_facadeData: ISaveFacadeData): IHingePriceParams[] | undefined {
        // TODO добавить
        return undefined;
    }

    public static calculateFacadeSizes(
        initSizes: TFacadeInitSizes,
        corpusSizes: TSizes,
        parentData: TDataForSizeByParent,
        gaps?: TFacadeGap,
        calculateSizes?: TOptionalFacadeSizes
    ): TFacadeSizes {
        let facadeSizes: TFacadeSizes;
        let initWidth: string;
        let initHeight: string;

        initWidth = '' + initSizes.width;
        initHeight = '' + initSizes.height;
        if (calculateSizes) {
            if (calculateSizes.width !== undefined && calculateSizes.width !== "") {
                initWidth = '' + calculateSizes.width;
            }
            if (calculateSizes.height !== undefined && calculateSizes.height !== "") {
                initHeight = '' + calculateSizes.height;
            }
        }
        facadeSizes = {
            width: this.calculateSizeByParent(initWidth, corpusSizes.length, parentData) -
                (this.getGapValue(SIDE_TYPE_LEFT, gaps) + this.getGapValue(SIDE_TYPE_RIGHT, gaps)),
            height: this.calculateSizeByParent(initHeight, corpusSizes.height, parentData) -
                (this.getGapValue(SIDE_TYPE_TOP, gaps) + this.getGapValue(SIDE_TYPE_BOTTOM, gaps)),
        };

        return facadeSizes;
    }

    public static getGapValue(sideType: keyof TFacadeGap, gaps?: TFacadeGap): number {
        let gap: number = 0;
        let sideGap: number | undefined;
        if (gaps && gaps[sideType] !== undefined) {
            sideGap = gaps[sideType];
            if (sideGap !== undefined) {
                gap = sideGap;
            }

        }

        return gap;
    }

    public static calculateSizeByParent(
        size: string | number,
        parentSize: number,
        parentData: TDataForSizeByParent
    ): number {
        let operator;
        let sizeValue;
        let sizeFormula;
        let formulaData;

        if (isNaN(+parentSize)) {
            throw new Error('error-ThreeHelper-calculateSizeByParent-parentSize');
        }
        if (typeof size === 'number') {
            return size;
        }
        operator = size.substring(0, 1);
        switch (operator) {
            case '%':
                sizeValue = Math.round(+size.substring(1) * +parentSize / 100);
                break;
            case '=':
                sizeFormula = this.prepareSizeFormula(size.substring(1), parentSize, parentData);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                formulaData = parentData;
                // eslint-disable-next-line no-eval
                sizeValue = eval(sizeFormula);
                break;
            default:
                sizeValue = +(+size).toFixed(2);
                break;
        }
        if (isNaN(+sizeValue)) {
            console.error('calculateSizeByParent isNaN');
            sizeValue = 0;
        }

        return sizeValue;
    }

    public static prepareSizeFormula(
        formula: string,
        parentSize: number,
        parentData: TDataForSizeByParent
    ): string {
        let resultFormula;

        resultFormula = formula.replace(/{([^}]*)}/g, (m, formulaItem) => {
            return '' + this.calculateSizeByParent(formulaItem, parentSize, parentData);
        });

        return resultFormula;
    }

    public static calculateFurnitureOffer(
        furnitureParams: IFurniturePriceParams,
        offers: IImportOffer[]
    ): IImportOffer | undefined {
        let offer: IImportOffer;

        for (offer of offers) {
            if (offer.width === furnitureParams.width &&
                offer.height === furnitureParams.height &&
                offer.functionalType === furnitureParams.functionalType &&
                (!furnitureParams.kitCode || (offer.kitCodes && offer.kitCodes.includes(furnitureParams.kitCode))) &&
                (!furnitureParams.furnitureType || (offer.furnitureType === furnitureParams.furnitureType))
            ) {
                return offer;
            }
        }

        return undefined;
    }

    public static calculateTabletopOffer(
        tabletopParams: IAccessoryPriceParam,
        offers: IImportOffer[]
    ): IImportOffer | undefined {
        let offer: IImportOffer;

        for (offer of offers) {
            if (offer.width === tabletopParams.sizes.length &&
                offer.height === tabletopParams.sizes.height &&
                offer.depth === tabletopParams.sizes.width &&
                offer.functionalType === tabletopParams.functionalType &&
                offer.corpusMaterial === tabletopParams.corpusMaterial &&
                (tabletopParams.sideType=== undefined || offer.sideType === this.calculateSideType(tabletopParams.sideType, offer.sideType))
            ) {
                return offer;
            }
        }

        return undefined;
    }

    public static getCreateObjectDynamicSettings(
        objectData: ICreateObjectData
    ): ICreateObjectSelectOptions {
        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let selectOptions: ICreateObjectSelectOptions = {};

        for (option of objectData.options) {
            if ('isGroup' in option) {
                for (childOption of option.options) {
                    if ('items' in childOption && ['width', 'height', 'depth'].includes(childOption.id)) {
                        if (!selectOptions[option.id]) {
                            selectOptions[option.id] = {}
                        }
                        if (!Array.isArray(selectOptions[option.id])) {
                            (selectOptions[option.id] as {
                                [key: string]: TSelectItem[]
                            })[childOption.id] = (childOption as IOptionSelect).items;
                        }
                    }
                }
            } else {
                if ('items' in option && (option as IOption).id === 'width') {
                    selectOptions[(option as IOption).id] = (option as IOptionSelect).items;
                }
            }
        }

        return selectOptions;
    }

    public static calculateModuleOffer(params: IModulePriceParams, offers: IImportOffer[]): IImportOffer | undefined {
        let offer: IImportOffer;

        for (offer of offers) {
            if (offer.width === params.width &&
                offer.height === params.height &&
                offer.depth === params.depth &&
                (!params.corpusMaterial || offer.corpusMaterial === params.corpusMaterial) &&
                (!params.facadeMaterial ||
                    params.facadeMaterial === offer.facadeMaterial) &&
                (!params.facadeMaterial2 || offer.facadeMaterial2 === params.facadeMaterial2) &&
                (!params.panelMaterial || offer.panelMaterial === params.panelMaterial) &&
                (!params.frameMaterial || offer.frameMaterial === params.frameMaterial) &&
                (!params.tabletops || this.checkOfferTabletopMaterial(offer.tabletopMaterial, params.tabletops)) &&
                offer.sideType === this.calculateSideType(params.sideType, offer.sideType)) {
                return offer;
            }
        }

        return undefined;
    }

    public static checkOfferTabletopMaterial(
        offerTabletopMaterial?: string,
        tabletopParams?: IAccessoryPriceParam[]
    ): boolean {
        let tabletopParam: IAccessoryPriceParam;

        if (tabletopParams && offerTabletopMaterial) {
            for (tabletopParam of tabletopParams) {
                if (offerTabletopMaterial !== tabletopParam.corpusMaterial + '_' + tabletopParam.sizes.height) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    public static calculateSideType(
        sideType: TDirectionSideType,
        findSideType: TDirectionSideType,
        isFlip?: boolean
    ): TDirectionSideType {
        if (sideType === SIDE_TYPE_LEFT && findSideType === SIDE_TYPE_DEFAULT) {
            return SIDE_TYPE_DEFAULT;
        }
        if (sideType === SIDE_TYPE_RIGHT && findSideType === SIDE_TYPE_DEFAULT) {
            return SIDE_TYPE_DEFAULT;
        }
        if (isFlip && sideType === SIDE_TYPE_LEFT) {
            return SIDE_TYPE_RIGHT;
        }
        if (isFlip && sideType === SIDE_TYPE_RIGHT) {
            return SIDE_TYPE_LEFT;
        }

        return sideType;
    }

    public static calculateModuleFacadesOffers(params: IModulePriceParams, offers: IImportOffer[]): IImportOffer[] {
        let offer: IImportOffer;
        let resultOffers: IImportOffer[] = [];

        for (offer of offers) {
            if (offer.width === params.width &&
                offer.height === params.height &&
                offer.depth === params.depth &&
                (!params.corpusMaterial || offer.corpusMaterial === params.corpusMaterial) &&
                (!params.facadeMaterial ||
                    (params.facadeMaterial && offer.facadeMaterial.includes(params.facadeMaterial))) &&
                (!params.facadeMaterial2 || offer.facadeMaterial2 === params.facadeMaterial2) &&
                (!params.panelMaterial || offer.panelMaterial === params.panelMaterial) &&
                (!params.frameMaterial || offer.frameMaterial === params.frameMaterial) &&
                offer.sideType === this.calculateSideType(params.sideType, offer.sideType)) {
                resultOffers.push(offer);
            }
        }

        return resultOffers;
    }

}