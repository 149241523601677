import {ThreeBuiltInEquipment} from '../ThreeBuiltInEquipment';
import {Box3, Mesh, Object3D, Vector3} from 'three';

export class ThreeHobEquipment extends ThreeBuiltInEquipment {

    public setLoadModel(type: string, details: Object3D[]) {
        super.setLoadModel(type, details);
        let child: Object3D;

        for (child of this.threeModel.children) {
            if(child.name !== 'hole'){
                child.name = `Hob_${Math.random().toString(36).slice(2)}`
            }         
            if (child.name === 'hole' && child instanceof Mesh) {
                child.material.colorWrite = false;
                child.renderOrder = 1;
            }      
        }
    }

    protected initPosition(): Vector3 {
        let cover: Box3;
        let initPosition: Vector3;

        initPosition = new Vector3();

        cover = this.unit.getCoverBox()
        initPosition.y = cover.max.y;
        initPosition.z -= 40;

        return initPosition;
    }
}