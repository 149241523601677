import React from "react";
import { IClassNameProps } from "@bem-react/core";
import FormPlinth, { INewPlinthData } from "../FormPlinth/FormPlinth";
import { useDispatch } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { 
  CHANGE_EDIT_MODE,
   HIDE_LOADING, INIT_EDIT_MATERIAL, SHOW_LOADING } from "../../../../constants";
import { Button, cnButton } from "../../../components/Button";
import * as materialServices from "../api/MaterialServices";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { INewTextureData } from "../FormPlinth/Textures/Textures";
import { ITextureData } from "../../../../../common-code/interfaces/materials/ITextureData";
import { ISaveTexture } from "../../../../../common-code/interfaces/materials/ISaveTexture";
import { TTextureExtension } from "common-code/lib/types/TTextureExtension";
import { cn } from "@bem-react/classname";
import { useUrlSearchParam } from "../../../../hooks/useSearchParams";
import { TEditMode } from "../../../../../common-code/types/TEditMode";
import { TMaterialEditMode } from "../../../../../common-code/types/TMaterialEditMode";
import "./EditPlinths.css";

interface IEditPlinthProps extends IClassNameProps {
  service: KitchenService;
  savePlinth: (
    plinthListNew: IPlinthData[],
    plinthMode: TMaterialEditMode
  ) => Promise<void>;
  saveImagePreview: (
    imageNew: File,
    plinthId: string,
    materialMode: TMaterialEditMode
  ) => Promise<string | null>;
}

const cnEditPlinths = cn("EditPlinths");

function EditPlinths({
  service,
  savePlinth,
  saveImagePreview,
}: IEditPlinthProps) {

  const { t } = useTranslation();
  const isEditMode = useSelector(
    (state: AppState) => state.wizard.editModule.isEditMode
  );
  const plinths = useSelector((state: AppState) => state.wizard[isEditMode]);
  const dispatch = useDispatch();
  const collectionId = useUrlSearchParam('collection')
  const initialValue = useSelector((state: AppState) => state.wizard.editMaterial.itemPlinth);
  const [itemPlinth, setItemPlinth] = React.useState<INewPlinthData | null>(initialValue || null);

  const handleToggleMode = (str: TEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({ type: INIT_EDIT_MATERIAL });
  };

  const createPlinth = (plinth: INewPlinthData) => {
    service.addPlinthObject(plinth);
    setItemPlinth(plinth);
  };

  const saveTextures = async (
    texturesNew: INewTextureData[],
    textures: ITextureData[] | undefined,
    idMaterial: string
  ) => {
    await Promise.all(
      texturesNew.map(async (textureNew) => {
        // сохраняем текстуру на сервер
        const saveTextureData: ISaveTexture = {
          id: `${textureNew.type}-${idMaterial}-${collectionId}`,
          file: textureNew.fileNew,
          type: textureNew.type,
        };

        const textureUrl = await materialServices.saveTexture(saveTextureData);
        if(!textureUrl) return textures = undefined;

        // формируем новую текстуру
        const file = textureNew.fileNew.name;
        const extension = file.split(".")[1] as TTextureExtension;
        const { url, fileNew, ...rest } = textureNew;
        const texture = {
          ...rest,
          path: textureUrl.url as string,
          file,
          extension,
        };

        // проверяем есть ли такая текстура
        const existingTexture = textures?.find(
          (textureItem) => textureItem.type === texture.type
        );

        if (existingTexture) {
          // если есть - перезаписываем
          textures = textures?.map((textureItem) =>
            textureItem.type === texture.type ? texture : textureItem
          );
        } else {
          // если нет - добавляем
          textures = [...(textures || []), texture];
        }
      })
    );

    return textures;
  };


  const savePlinthsCollection = async (itemPlinth: INewPlinthData) => {
    // проверяем корректность заполнения формы
    if (!itemPlinth) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      plinths.length > 0 &&
      plinths.find((plinth: IPlinthData) => plinth.id === itemPlinth.id)
    ) {
      alert(
        "Цоколь с таким наменованием уже существует в коллекции. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });

    if (itemPlinth.texturesNew) {
      const textures = await saveTextures(
        itemPlinth.texturesNew,
        itemPlinth.textures as ITextureData[],
        itemPlinth.id
      );
      if(!textures) {
        alert(
          "Ошибка при сохранении текстур. Попробуйте еще раз."
        );
        dispatch({ type: HIDE_LOADING });
        return
      }
      itemPlinth.textures = textures;
      // удаляем ключ texturesNew
      delete itemPlinth.texturesNew;
    }

    // сохраняем preview материала
    if (itemPlinth.imageNew) {
      const imageUrl = await saveImagePreview(
        itemPlinth.imageNew,
        itemPlinth.id,
        "plinths"
      );
      if (!imageUrl) {
        alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemPlinth.image = imageUrl;
      // удаляем ключ imageNew
      delete itemPlinth.imageNew;
    }

    // сохраняем материал
    const plinthListNew = [...plinths, itemPlinth];
    savePlinth(plinthListNew, "plinths").then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    });
  };

  const editPlinthsCollection = async (itemPlinth: INewPlinthData) => {
    // проверяем корректность заполнения формы
    if (!itemPlinth) {
      alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      plinths.length > 0 &&
      !plinths.find((plinth: IPlinthData) => plinth.id === itemPlinth.id)
    ) {
      alert("Цоколя с таким наменованием нет. Укажите другое название.");
      return;
    }
    dispatch({ type: SHOW_LOADING });

    if (itemPlinth.texturesNew) {
      const textures = await saveTextures(
        itemPlinth.texturesNew,
        itemPlinth.textures as ITextureData[],
        itemPlinth.id
      );
      if(!textures) {
        alert(
          "Ошибка при сохранении текстур. Попробуйте еще раз."
        );
        dispatch({ type: HIDE_LOADING });
        return
      }
      itemPlinth.textures = textures;
      // удаляем ключ texturesNew
      delete itemPlinth.texturesNew;
    }

    // сохраняем preview материала
    if (itemPlinth.imageNew) {
      const imageUrl = await saveImagePreview(
        itemPlinth.imageNew,
        itemPlinth.id,
        "plinths"
      );
      if (!imageUrl) {
        alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemPlinth.image = imageUrl;
      // удаляем ключ imageNew
      delete itemPlinth.imageNew;
    }

    // сохраняем цоколь
    const plinthListNew = plinths.map((plinth: IPlinthData) =>
      plinth.id === itemPlinth.id ? itemPlinth : plinth
    );
    savePlinth(plinthListNew, "plinths").then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    });
  };

  return (
    <>
      <FormPlinth
        itemPlinth={itemPlinth}
        createPlinth={createPlinth}
      />

      <div className={cnEditPlinths("Buttons-Container")}>
        <Button
          className={cnEditPlinths(cnButton(), { type: "prev" })}
          leftIcon={{
            path: "arrow-left",
            width: 20,
            height: 20,
            marginRight: 8,
          }}
          text={t("Предыдущий шаг") || undefined}
          callBack={() => handleToggleMode("")}
          mods={{
            mods: {
              color: "black",
              bkg: "white",
              border: "black",
              fill: "black",
            },
          }}
          type={"button"}
        />
      </div>

      {itemPlinth && (
        <>
          <div className={cnEditPlinths("Button-Add")}>
            <Button
              className={cnEditPlinths(cnButton())}
              type={"button"}
              text={"Добавить новый цоколь в коллекцию"}
              leftIcon={{
                path: "plus2",
                width: 16,
                height: 16,
                marginRight: 8,
              }}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => savePlinthsCollection(itemPlinth)}
            />
            <Button
              className={cnEditPlinths(cnButton())}
              type={"button"}
              text={"Заменить редактируемый цоколь в коллекции"}
              leftIcon={undefined}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => editPlinthsCollection(itemPlinth)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EditPlinths;
